import styled from "styled-components";
import { ReactComponent as CalendarIcon } from "../../../src/assets/Calendar.svg";

export const StyledLabel = styled.label`
  position: relative;
  height: 100%;
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
  font-size: ${({ theme }) => theme.fontSizes.font8};
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  left: 3px;
`;

export const CustomHeaderWrapper = styled.div`
  margin: 10;
  display: flex;
  justify-content: center;
`;

export const ReactDatePicker = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  position: relative;
  input {
    border-radius: 4px;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: ${({ theme }) => theme.fontSizes.font6};
    font-weight: 400;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFFF"};
    background-clip: padding-box;
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    margin-bottom: 0.25rem !important;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    background: url(${CalendarIcon});
    background-repeat: no-repeat;
    background-size: 190% 50%;
    background-position: left;
    cursor: pointer;

    &:focus {
      border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
      border: 2px solid;
    }
  }
  .react-datepicker-wrapper {
    display: block;
  }
`;
