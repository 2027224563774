import styled from "styled-components";

export const StyledSpan = styled.span`
  height: ${(props) => `calc(100vh - ${props.height}px)`};
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  @media screen and (max-width: 800px) {
    font-size: ${({ theme }) => theme.fontSizes.font5} !important;
  }
`;

export const StatusDiv = styled.div`
  .status-pending {
    border-color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .status-in-progress {
    border-color: ${({ theme }) => theme.colors.orangePrimary || "#DD9000"};
    color: ${({ theme }) => theme.colors.orangePrimary || "#DD9000"};
  }
  .status-failed {
    border-color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
    color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  }
  .status-completed {
    border-color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
  }
`;

export const StatusSpan = styled.div`
  border: 1px solid;
  border-radius: 4px;
  width: 130px;
  float: left;
  text-align: center;
  line-height: ${({ theme }) => theme.lineHeights.line4};
  position: relative;
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* semi-transparent white */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
