import styled from "styled-components";

export const StyledSpan = styled.span`
  height: ${(props) => `calc(100vh - ${props.height}px)`};
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: calc(100vh - 300px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
