import styled from "styled-components";

export const NoDataMessage = styled.div`
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
`;

export const GPSTrackingWrapper = styled.div`
  position: relative;
  height: 340px;
`;

export const Disclaimer = styled.div`
  font-size:  ${({ theme }) => theme.fontSizes.font6};
  text-align: left;
  color: #676767;
  font: normal normal normal 16px/22px Open Sans;
`;
