import styled from "styled-components";

export const DashboardContainer = styled.div`
  position: relative;
`;

export const CarouselContainer = styled.div``;
export const CarouselDiv = styled.div``;

export const FailedInspectionDiv = styled.div`
  .noReportsDiv {
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  }
  .rowHeight {
    height: auto !important;
    max-height: calc(-565px + 100vh);
  }
`;

export const NoReportsDiv = styled.div``;

export const GraphContainer = styled.div`
  padding-top: 8px;
  border: 1px solid #eee;
  margin-bottom: 8px;
  .apexcharts-toolbar {
    z-index: 1 !important;
  }
  .apexcharts-menu-item {
    padding: 4px 0px;
    text-align: center;
    margin-bottom: 2px;
    &:hover {
      background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
      color: ${({ theme }) => theme.colors.gray7 || "#FFFFFF"};
    }
  }
`;

export const StyledCol = styled.div`
  text-align: left;
  margin-top: 20px;
  display: flex;
  @media screen and (max-width: 1100px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* semi-transparent white */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftSection = styled.div`
  flex: 2;
  width: 66%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const RightSection = styled.div`
  flex: 1;
  width: 34%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
