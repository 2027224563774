import styled from "styled-components";

export const ErrorLabel = styled.label`
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.font8};
  color: rgb(221, 0, 0) !important;
  div label.text-danger {
    color: rgb(221, 0, 0) !important;
  }
`;
