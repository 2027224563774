import styled from "styled-components";

export const PasswordRulesWrapper = styled.div`
  .alignTop {
    align-items: flex-start;
  }
  padding: 5px;
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  text-align: left;
  transition: border 0.5s;
`;

export const PassNotMatch = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  text-align: left;
  color: ${({ theme, isError }) => (isError ? theme.colors.redPrimary || "#DD0000" : "default")};
  display: flex;
  align-items: center;
  line-height: 14px;
`;
export const IconDanger = styled.div`
  font-size: 22px !important;
  color: ${({ theme, isError }) => (isError ? theme.colors.redPrimary || "#DD0000" : "default")};
  float: left;
  margin-right: 10px;
`;
export const RulesText = styled.div`
  line-height: ${({ theme }) => theme.lineHeights.line6};
`;
export const LineDIV = styled.div`
  color: ${({ theme, isError }) => (isError ? theme.colors.redPrimary || "#DD0000" : "default")};
`;
