import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import BreadCrumb from "../../../../components/BreadCrumb.component";
import Button from "../../../../components/Button/Button.component";
import ModalComponent from "../../../../components/ModalPopupComponent/ModalPopup.component";
import AddChartsModal from "./Charts/AddCharts/AddChartsModal.component";
import ChartsList from "./Charts/ChartsList/ChartsList.component";
import ChartsDetails from "./Charts/ChartsDetails/ChartsDetails.component";
import SearchBox from "../../../../components/SearchBox/Searchbox.component";
import {
  AnalyticsPageWrapper,
  MenuContainer,
  SearchContainer,
  ButtonDivStyle,
  SearchDiv,
  LoaderOverlay,
  Container,
  ContentWrapper,
  LeftSection,
  RightSection,
} from "./Charts/ChartsOverview.component.styles";
import SieraLoader from "../../../../components/SieraLoader/SieraLoader.component";
import { getJwtTokenForCharts, getUserChartsList } from "../../../../redux/analyticsPage/action";
import ChartsFilters from "./Charts/ChartsFilters/ChartsFilter.component";
function AnalyticsPage({
  updateActiveNavItem,
  loginDetails,
  getJwtTokenForCharts,
  getUserChartsList,
  getUserChartsListLoading,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showAddChartsModal, setShowAddChartsModal] = useState(false);
  const [searchInput, setSearchInput] = useState(""); // State for search input
  const [chartListData, setChartListData] = useState([]);
  const [showFilters, setShowFilters] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    updateActiveNavItem("analyticsPage");
    getJwtTokenForCharts();
    getUserChartsList();
  }, []);

  const handleOpenAddChartsModal = () => {
    setShowAddChartsModal(true);
  };

  const handleCloseAddChartsModal = () => {
    setShowAddChartsModal(false);
  };
  const handleSaveAddChartsModal = () => {
    setShowAddChartsModal(false);
  };

  const handleSearchInputChange = (event) => {
    let newSearchInput = event?.target?.value;
    if (!newSearchInput) {
      newSearchInput = "";
    }
    setSearchInput(newSearchInput);
  };

  useEffect(() => {
    setShowLoader(getUserChartsListLoading);
  }, [getUserChartsListLoading]);

  return (
    <div>
      <BreadCrumb />
      <>
        {/* <Row style={{ paddingTop: "8px" }} className="m-0">
          <div className="mr-2 pl-0">
            <Button
              iconClass="add"
              label={t("Add Charts")}
              onClick={() => handleOpenAddChartsModal()}
              showIcon
              buttonType="secondary"
            />
          </div>
        </Row> */}

        <Container>
          <ContentWrapper>
            {showLoader && (
              <LoaderOverlay>
                <SieraLoader OnlyLoaderIcon size="small" />
              </LoaderOverlay>
            )}
            <LeftSection>
              <div className="chartsList_hieght">
                {/* <ChartsList
              filterAccountId={filterAccountId}
              style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
              /> */}
                <AnalyticsPageWrapper>
                  <MenuContainer>
                    <SearchContainer>
                      <ButtonDivStyle>
                        <Button
                          iconClass="add"
                          iconOnly
                          showIcon
                          label="Add Library"
                          onClick={handleOpenAddChartsModal}
                        ></Button>
                      </ButtonDivStyle>
                      {showAddChartsModal && (
                        <ModalComponent
                          show={showAddChartsModal}
                          size="lg"
                          withHeader={false}
                          handleClose={handleCloseAddChartsModal}
                        >
                          <AddChartsModal
                            handleClose={handleCloseAddChartsModal}
                            onSave={handleSaveAddChartsModal}
                          />
                        </ModalComponent>
                      )}
                      <SearchDiv>
                        <SearchBox
                          iconOnly
                          isIcon
                          placeholder={t("SEARCH_CHART")}
                          value={searchInput}
                          onChange={handleSearchInputChange}
                          searchText={searchInput}
                          setSearchText={handleSearchInputChange}
                        />
                      </SearchDiv>
                    </SearchContainer>

                    <ChartsList searchInput={searchInput}></ChartsList>
                  </MenuContainer>
                </AnalyticsPageWrapper>
              </div>
            </LeftSection>
            <RightSection>
              {showFilters && <ChartsFilters />}

              <ChartsDetails />
            </RightSection>
          </ContentWrapper>
        </Container>
      </>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  getUserChartsListLoading: state.analyticsPage?.getUserChartsListLoading,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  getJwtTokenForCharts: () => dispatch(getJwtTokenForCharts()),
  getUserChartsList: () => dispatch(getUserChartsList()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage));
