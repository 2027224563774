import React from "react";

// Bootstrap
import { Navbar } from "react-bootstrap";

// Styled Components
import { CopyrightText, FooterWrapper } from "./Footer.styles";

import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
  const version = import.meta.env?.VITE_APP_VERSION;
  const { t } = useTranslation();

  return (
    <>
      {/* Navbar for the Footer */}
      <Navbar
        className="footer-height"
        style={{
          justifyContent: "center",
          backgroundColor: "#E6E6E6",
          boxShadow: "0px 8px 8px -6px rgba(0,0,0,.5)",
        }}
        collapseOnSelect
        expand="lg"
      >
        <FooterWrapper>
          <div style={{ width: "100px" }}></div>
          <CopyrightText>
            <Trans>Proudly Built in Austin, TX. Copyright</Trans> {new Date().getFullYear()}.&nbsp;
            <Trans>Stocked Robotics, Inc. dba SIERA.AI</Trans>
          </CopyrightText>
          <div style={{ width: "100px", textAlign: "right" }}>v. {version}</div>
        </FooterWrapper>
      </Navbar>
    </>
  );
};

export default Footer;
