import styled from "styled-components";

export const SearchBoxDiv = styled.div`
  width: 100%;
`;

export const SearchRefreshWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  height: 56px;
`;
