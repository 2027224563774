import { useState, useEffect } from "react";
import { connect } from "react-redux";

import Filters from "./Filters.component";
import BreadCrumb from "../../../components/BreadCrumb.component";
import DeviceDetails from "./DeviceDetails.components";
import { DeviceTableWrapper } from "./DeviceInformation.component.styles";
import { getDeviceInformation, resetData } from "../../../redux/deviceInformation/deviceInfo.action";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import DeviceInfoTableWrapper from "./DeviceInfoTableWrapper/DeviceInfoTableWrapper.component";

const DeviceInformation = (props) => {
  const {
    getDeviceInformation,
    selectedAccountId,
    selectedWorksiteId,
    selectedSieraProductId,
    selectedAssetTypeId,
    pageSize,
    pageNo,
    resetData,
    accountListById,
    updateActiveNavItem,
    isLoading,
    deviceInformationList,
    currentTimeZone,
    isSuperAdmin,
    loginDetails,
  } = props;

  const [showDeviceDetails, setShowDeviceDetails] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState({});
  const [showMoreFilter, setShowMoreFilter] = useState(false);

  useEffect(() => {
    updateActiveNavItem("deviceInfo");
  }, []);

  useEffect(() => {
    if (isSuperAdmin) {
      if (selectedAccountId) {
        getDeviceInformation({ searchText: "all" });
      }
    } else {
      getDeviceInformation({ searchText: "all" });
    }
  }, [selectedAccountId, selectedWorksiteId, selectedSieraProductId, selectedAssetTypeId, pageSize, pageNo]);

  useEffect(() => {
    resetData();
  }, [selectedAccountId, selectedWorksiteId, selectedSieraProductId, selectedAssetTypeId]);

  const getLatestSelectedAsset = (selectedAsset) => {
    const updatedAsset = deviceInformationList.filter((asset) => {
      return asset._id === selectedAsset._id;
    });
    if (!updatedAsset?.[0]) {
      setShowDeviceDetails(false);
    }
    return updatedAsset?.[0] || {};
  };

  const accountName = isSuperAdmin
    ? accountListById?.[selectedAccountId]?.companyName
    : loginDetails?.companyName;

  return (
    <>
      {!showDeviceDetails && (
        <DeviceTableWrapper>
          <BreadCrumb />
          <Filters
            getDeviceInformation={getDeviceInformation}
            showMoreFilter={showMoreFilter}
            setShowMoreFilter={setShowMoreFilter}
          />
          <DeviceInfoTableWrapper
            setShowDeviceDetails={setShowDeviceDetails}
            setSelectedAsset={setSelectedAsset}
            rowHeight={showMoreFilter ? 342 : 286}
          />
        </DeviceTableWrapper>
      )}
      {showDeviceDetails && (
        <DeviceDetails
          setShowDeviceDetails={setShowDeviceDetails}
          selectedAsset={getLatestSelectedAsset(selectedAsset)}
          accountName={accountName}
          getDeviceInformation={getDeviceInformation}
          isLoading={isLoading}
          currentTimeZone={currentTimeZone}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    accountListById: state.dropdownFilters.accountListById,
    selectedAccountId: state.deviceInfo.selectedAccountId,
    selectedWorksiteId: state.deviceInfo.selectedWorksiteId,
    selectedSieraProductId: state.deviceInfo.selectedSieraProductId,
    selectedAssetTypeId: state.deviceInfo.selectedAssetTypeId,
    isLoading: state.deviceInfo.isLoading,
    pageSize: state.deviceInfo.pageSize,
    pageNo: state.deviceInfo.pageNo,
    deviceInformationList: state.deviceInfo.data,
    currentTimeZone: state.settings.currentTimeZone,
    isSuperAdmin: state.user.isSuperAdmin,
    loginDetails: state.user.loginDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDeviceInformation: (data) => dispatch(getDeviceInformation(data)),
  resetData: () => dispatch(resetData()),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInformation);
