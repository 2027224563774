import styled from "styled-components";
export const EditProfileContainer = styled.div``;

export const SettingsWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  border-radius: 3px;
  width: 100%;
  margin-left: 0% !important;
  @media (max-width: 768px) {
    margin-bottom: 20px;
    width: 100%;
  }
`;

export const AccountProfileContainer = styled.div`
  width: 100%;
  margin: auto;
`;

export const InputProfileDiv = styled.div`
  width: 96%;
  margin: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ImageAvatar = styled.div`
  float: left;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-position: center;
  background-size: 100%;
  border: 0.8px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  background-repeat: no-repeat;
  align-items: flex-end;
  display: flex;
  overflow: hidden;
  justify-content: space-around;
  cursor: pointer;
  position: relative;
  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
    margin: 8px 0px;
  }
`;

export const ChangeImage = styled.div`
  position: absolute;
  font-size: 0.6em !important;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  bottom: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  svg {
    width: 0.8em;
    height: 0.8em;
  }
  span {
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    display: block;
  }
  input[type="file"] {
    visibility: hidden;
  }
`;

export const TopImageAccountDiv = styled.div`
  display: block;
  overflow: hidden;
`;
export const NameContainerDiv = styled.div`
  display: block;
  overflow: hidden;
  float: left;
  margin: 25px 10px;
`;

export const AccountProfileDetail = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  text-align: left;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  position: relative;
  top: -10px;
  text-transform: capitalize;
`;

export const UserNameDiv = styled.div`
  width: 100%;
  overflow: hidden;
  font-size: ${({ theme }) => theme.fontSizes.font1} !important;
  text-align: left;

  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  position: relative;

  .form-group {
    float: left;
  }
  label.form-label {
    display: none;
  }
  .input-group {
    input {
      border: none;
      padding: 0;
      color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
      font-weight: 600;
      font-size: ${({ theme }) => theme.fontSizes.font1} !important;
      text-align: left;
      background-color: transparent !important;
    }
  }
`;

export const DivScroller = styled.div`
  clear: both;
  height: calc(100vh - 260px);
  overflow-y: auto;
  @media (max-width: 1100px) {
    /* height: ${({ isSuperAdmin }) => (isSuperAdmin ? "calc(100vh - 400px)" : "calc(100vh - 330px)")}; */
    height: 100%;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"} !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
`;

export const InputWrapper = styled.div`
  margin-top: ${({ marginTop }) => marginTop};
`;

export const ButtonDiv = styled.div`
  text-align: right;
  @media (max-width: 1100px) {
    button[title="Cancel"] {
      float: left;
    }
  }
`;
export const ButtonWrapper = styled.div`
  width: 96%;
  margin: 10px auto;
  display: flex;
  justify-content: end;
  @media (max-width: 1100px) {
    display: block;
  }
`;

export const InputProfileDivCont = styled.div`
  clear: both;
  margin: 5px auto;
  overflow: hidden;
`;

export const Divider = styled.div`
  clear: both;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5 || "#CCCCCC"};
`;

export const ContainerImage = styled.div`
  margin-bottom: 16px;
  display: flex;
  margin-top: 10px;
  @media (max-width: 1100px) {
    display: block;
    overflow: hidden;
    padding: 0px 10px;
  }
`;

export const LabelContainer = styled.div`
  flex: 1;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const LeftLabel = styled.div`
  float: left;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const RightInput = styled.div`
  float: right;
  width: 40%;
`;

export const SpanTop = styled.div`
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;
export const SpanBottom = styled.div`
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
`;

export const PhoneErrorDiv = styled.div`
  clear: both;
  margin: 5px auto;
  overflow: hidden;
`;

export const ButtonContainerStyle = styled.div`
  display: flex;
  padding: 15px;
`;
export const ButtonContainerDiv = styled.div`
  flex: 1;
`;

export const MFAWrapper = styled.div`
  /* align-items: left;
  padding: 10px 10px 0 0;  */
`;
export const DropWrapper = styled.div`
  width: 40%;
  @media (max-width: 1100px) {
    width: 100%;
    margin-top: 5px;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  @media (max-width: 1100px) {
    padding: 0px 8px;
  }
`;

export const MFADropWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  @media (max-width: 1100px) {
    padding: 0px 8px;
    display: block;
  }
`;

export const ModalBodyHeight = styled.div`
  height: calc(100vh - 260px);
  align-content: center;
  text-align: center;
  .ReactCrop {
    align-items: center;
    justify-content: center;
  }
  img.ReactCrop__image {
    /*  height: calc(100vh - 260px); */
    height: 100%;
    max-height: calc(100vh - 280px);
  }
`;

export const CameraIcon = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font5} !important;
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: calc(100vh - 280px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
