import styled from "styled-components";
import { RefreshIcon } from "../../../assets";

export const DeviceTableWrapper = styled.div``;

export const DeviceInfoWrapper = styled.div`
  margin-top: 15px;
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  border-radius: 4px;
  margin-bottom: 50px;
`;

export const RefreshButtonDiv = styled.div`
  overflow: hidden;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  @media (max-width: 800px) {
    height: 100%;
  }
`;

export const RefreshButton = styled.div`
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  background-size: contain;
  background-repeat: no-repeat;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  float: right;
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
`;

export const LoadingFrame = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background: #aaa;
  opacity: 0.1;
`;
