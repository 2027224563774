import styled from "styled-components";

export const StyledSpan = styled.span`
  height: ${(props) => `calc(100vh - ${props.height}px)`};
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: calc(100vh - 300px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px;
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  border: 1.5px solid
    ${({ status, theme }) =>
      status === "lock"
        ? theme?.colors?.redPrimary || "#DD0000"
        : status === "unlock"
          ? theme?.colors?.greenPrimary || "#11A70B"
          : "gray"};
  border-radius: 4px;
  line-height: ${({ theme }) => theme.lineHeights.line4};
  position: relative;
`;

export const StatusIcon = styled.span`
  margin-left: 8px;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.font4};
  color: ${({ status, theme }) =>
    status === "lock"
      ? theme?.colors?.redPrimary || "#DD0000"
      : status === "unlock"
        ? theme?.colors?.greenPrimary || "#11A70B"
        : "gray"};
  i {
    display: flex;
  }
  position: absolute;
  right: 10px;
`;
