import React from "react";
import ReactDOM from "react-dom";
import { Container, Popover, OverlayTrigger, Button, Row, Col } from "react-bootstrap";
//import { ReactComponent as ShareIcon } from "../assets/Share.svg";
// Styles
import "bootstrap/dist/css/bootstrap.css";
import styled from "styled-components";
const ShareIcon = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  cursor: pointer;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
`;
const PopoverCustom = ({ children, customLabelComponent }) => {
  let ref = React.useRef(null);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>{children}</Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      ref={(r) => (ref = r)}
      container={ref.current}
      trigger="click"
      placement="auto"
      overlay={popover}
      rootClose
    >
      {customLabelComponent ? (
        customLabelComponent
      ) : (
        <ShareIcon
          className="icon icon-share"
          // onClick={() => setReportId(data._id)}
        />
      )}
    </OverlayTrigger>
  );
};

export default PopoverCustom;
