import styled, { css, keyframes } from "styled-components";

export const TabWrapper = styled.div`
  /* width: 270px;
  margin-right: 15px;
  display: flex;
  flex-direction: column; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
    margin: 0;
  }

  .pro-sidebar {
    max-width: 100%;
    box-shadow: none;
    min-width: 100%;
    height: auto;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    z-index: 1;
    /*height: calc(100vh - 160px);*/
    flex: 1;
  }

  .pro-inner-item {
    /* padding: 8px 35px 10px 8px !important; */
  }
  .pro-sidebar > .pro-sidebar-inner {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    @media (max-width: 768px) {
      border: 0px;
      margin-bottom: 15px;
    }
  }
  .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: transparent;
  }
  .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: transparent;
  }
  .pro-sidebar .pro-menu {
    font-family: "Open Sans", sans-serif;
    padding-top: 0px;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    font-size: 14px;
  }
  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
    right: 8px;
  }
  .pro-sidebar .pro-menu .react-slidedown .pro-menu-item > .pro-inner-item > .pro-item-content {
    font-size: 14px;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:active {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.6);
    border-bottom: 0px;
    font-weight: 600;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.6);
    border-bottom: 0px;
    font-weight: 600;
  }
  .pro-sidebar .pro-menu .react-slidedown .pro-menu-item > .pro-inner-item:active {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    box-shadow: none;
    font-weight: 600;
  }
  .pro-sidebar .pro-menu .react-slidedown .pro-menu-item > .pro-inner-item:focus {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    box-shadow: none;
    font-weight: 600;
  }
  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    padding-left: 0px;
  }
  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 30px 8px 45px;
  }
  .pro-sidebar .pro-menu .pro-menu-item {
    font-size: 15px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
  .pro-sidebar .pro-menu .react-slidedown .pro-menu-item {
    font-size: 15px;
    border-bottom: 0px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    transform: rotate(45deg);
    width: 8px;
    height: 8px;
    border-color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }

  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    transform: rotate(224deg);
    width: 8px;
    height: 8px;
    border-color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .pro-sidebar .pro-menu .pro-menu-item.active {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item {
    background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    font-weight: bold;
    box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.6);
  }
`;

export const Container = styled.div`
  display: flex;
  margin-top: 15px;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const DropdownWrapper = styled.div`
  position: relative;
  margin-bottom: 8px;SIER-7602-activity-logs-typography
  z-index: 100;
  text-align: left;
  width: 100%;
`;

export const DesktopMenuAccordion = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ResponsiveMenuDropdown = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const MobileViewDropdown = styled.div`
  transition: all 0.5s ease;
  width: ${({ isSidebarVisible }) => (isSidebarVisible ? "0%" : "100%")};
  overflow: hidden;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const PanelView = styled.div`
  @media (max-width: 768px) {
    display: block;
  }
`;

export const ButtonWrapper = styled.button`
  background: transparent;
  border: 0px;
  height: 44px;
  i {
    font-size: 22px;
    margin: 5px;
    color: white;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const ContentWrapper = styled.div`
  display: ${({ isSidebarVisible }) => (isSidebarVisible ? "flex" : "block")};
  flex-direction: row;
  gap: ${({ isSidebarVisible }) => (isSidebarVisible ? "16px" : "0")};
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftSection = styled.div`
  flex: ${({ isSidebarVisible }) => (isSidebarVisible ? "1" : "0")};
  width: ${({ isSidebarVisible }) => (isSidebarVisible ? "25%" : "0%")};
  display: ${({ isSidebarVisible }) => (isSidebarVisible ? "initial" : "none")};
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  @media (max-width: 768px) {
    width: 100%;
    flex: 0;
  }
`;

export const RightSection = styled.div`
  flex: ${({ isSidebarVisible }) => (isSidebarVisible ? "3" : "1")};
  width: ${({ isSidebarVisible }) => (isSidebarVisible ? "75%" : "100%")};
  transition: width 0.3s ease-in-out;
  margin-left: auto;
  /* margin-bottom: ${({ isSidebarVisible }) => (isSidebarVisible ? "initial" : "50px")}; */
  @media (max-width: 768px) {
    width: 100%;
  }
`;
