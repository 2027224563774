import i18next from "i18next";
import "../../../../../constant/i18n/i18n";

export const getCustomFields = (type) => {
  if (type === "INDIVIDUAL_EMPLOYEE") {
    return [
      {
        name: "Phone",
        type: "PHONE",
        isRequired: false,
        isDisabled: false,
        description: "Phone number of the user",
      },
      {
        name: "Pin",
        type: "PIN",
        isRequired: true,
        isDisabled: true,
        description: "Special PIN for access to SIERA.AI inspections",
      },
    ];
  } else {
    return [
      {
        name: "Phone",
        type: "PHONE",
        isRequired: false,
        description: "Phone number of the user",
        isDisabled: false,
      },
      {
        name: "Pin",
        type: "PIN",
        isRequired: true,
        isDisabled: true,
        description: "Special PIN for access to SIERA.AI inspections",
      },
      {
        name: "Dashboard Login",
        type: "EMAILANDPASSWORD",
        isRequired: true,
        isDisabled: true,
        description: "Login credentials of the user",
      },
    ];
  }
};

export const allSteps = [
  {
    heading: i18next.t("User Type Overview"),
    iconClass: "user-type-overview",
  },
  {
    heading: i18next.t("User Type Permissions"),
    iconClass: "user-type-permissions",
  },
  {
    heading: i18next.t("User Type Details"),
    iconClass: "user-type-details",
  },
];

export const addUserTypeModalDefaultData = {
  name: "",
  description: "",
  accessLevel: "",
  accountId: "",
  permissions: [],
  customFields: [
    {
      name: "Phone",
      type: "PHONE",
      isRequired: false,
      description: "Phone number of the user",
    },
    {
      name: "Pin",
      type: "PIN",
      isRequired: true,
      description: "Special PIN for access to SIERA.AI inspections",
    },
    {
      name: "Dashboard Login",
      type: "EMAILANDPASSWORD",
      isRequired: true,
      description: "Login credentials of the user",
    },
  ],
};

const allSelected = {
  view: true,
  edit: true,
  add: true,
  deactivate: true,
};

export const accessLevelMapping = {
  ALL_ACCOUNTS: [
    {
      section: "DASHBOARD",
      permissions: [
        {
          label: "Reports",
          name: "REPORTS",
          action: {
            view: true,
          },
        },
        {
          label: "Analytics",
          name: "ANALYTICS",
          action: {
            view: true,
          },
        },
        {
          label: "Account Management",
          name: "ACCOUNT_MANAGEMENT",
          action: {
            view: true,
            edit: true,
            add: true,
            deactivate: true,
          },
        },
        {
          label: "Worksite Management",
          name: "WORKSITE_MANAGEMENT",
          action: {
            view: true,
            edit: true,
            add: true,
            deactivate: true,
          },
        },
        {
          label: "Asset Type Management",
          name: "ASSET_TYPE_MANAGEMENT",
          action: {
            view: true,
            edit: true,
            add: true,
          },
        },
        {
          label: "Asset Management",
          name: "ASSET_MANAGEMENT",
          action: {
            view: true,
            edit: true,
            add: true,
            deactivate: true,
          },
        },
        {
          label: "User Type Management",
          name: "USER_TYPE_MANAGEMENT",
          action: {
            view: true,
            edit: true,
            add: true,
            deactivate: true,
          },
        },
        {
          label: "User Management",
          name: "USER_MANAGEMENT",
          action: {
            view: true,
            edit: true,
            add: true,
            deactivate: true,
          },
        },
        {
          label: "Checklist Settings",
          name: "CHECKLIST_SETTINGS",
          action: {
            view: true,
            edit: true,
            add: true,
          },
        },
        {
          label: "System Settings",
          name: "SYSTEM_SETTINGS",
          action: {
            view: true,
            edit: true,
          },
        },
        {
          label: "Download Status",
          name: "DOWNLOAD_STATUS",
          action: {
            view: true,
          },
        },
        {
          label: "Error Logs",
          name: "ERROR_LOGS",
          action: {
            view: true,
          },
        },
        {
          label: "Device Information",
          name: "DEVICE_INFORMATION",
          action: {
            view: true,
          },
        },

        {
          label: "Activity Logs",
          name: "ACTIVITY_LOGS",
          action: {
            view: true,
          },
        },
        {
          label: "Troubleshoot Logs",
          name: "TROUBLESHOOTING_LOGS",
          action: {
            view: true,
          },
        },
      ],
    },
    {
      section: "TABLET",
      permissions: [
        {
          label: "Administrator Access",
          name: "ADMINISTRATOR_ACCESS",
          action: {
            view: true,
          },
        },
        {
          label: "Unlock Vehicle",
          name: "UNLOCK_VEHICLE",
          action: {
            view: true,
          },
        },
      ],
    },
  ],
  MULTIPLE_ACCOUNTS: [
    {
      section: "DASHBOARD",
      permissions: [
        {
          label: "Reports",
          name: "REPORTS",
          action: {
            view: true,
          },
        },
        {
          label: "Worksite Management",
          name: "WORKSITE_MANAGEMENT",
          action: {
            view: true,
            edit: true,
            add: false,
            deactivate: true,
          },
        },
        {
          label: "Asset Type Management",
          name: "ASSET_TYPE_MANAGEMENT",
          action: {
            view: true,
            edit: true,
            add: true,
            deactivate: true,
          },
        },
        {
          label: "Asset Management",
          name: "ASSET_MANAGEMENT",
          action: {
            view: true,
            edit: true,
            add: true,
            deactivate: false,
          },
        },
        {
          label: "User Type Management",
          name: "USER_TYPE_MANAGEMENT",
          action: {
            view: true,
            edit: true,
            add: true,
            deactivate: true,
          },
        },
        {
          label: "User Management",
          name: "USER_MANAGEMENT",
          action: {
            view: true,
            edit: true,
            add: true,
            deactivate: true,
          },
        },
        {
          label: "Checklist Settings",
          name: "CHECKLIST_SETTINGS",
          action: {
            view: true,
            edit: true,
            add: true,
            deactivate: true,
          },
        },
        {
          label: "System Settings",
          name: "SYSTEM_SETTINGS",
          action: {
            view: true,
            edit: true,
            add: true,
            deactivate: true,
          },
        },
        {
          label: "Inspect Asset",
          name: "INSPECT_ASSET",
          action: {
            view: true,
          },
        },
        {
          label: "Device Information",
          name: "DEVICE_INFORMATION",
          action: {
            view: true,
          },
        },

        {
          label: "Activity Logs",
          name: "ACTIVITY_LOGS",
          action: {
            view: false,
          },
        },
      ],
    },
    {
      section: "TABLET",
      permissions: [
        {
          label: "Administrator Access",
          name: "ADMINISTRATOR_ACCESS",
          action: {
            view: true,
          },
        },
        {
          label: "Unlock Vehicle",
          name: "UNLOCK_VEHICLE",
          action: {
            view: true,
          },
        },
        {
          label: "Perform Inspections",
          name: "PERFORM_INSPECTIONS",
          action: {
            view: true,
          },
        },
      ],
    },
  ],

  ALL_WORKSITES: [
    {
      section: "DASHBOARD",
      permissions: [
        {
          label: "Reports",
          name: "REPORTS",
          action: {
            view: true,
          },
        },
        {
          label: "Analytics",
          name: "ANALYTICS",
          action: {
            view: true,
          },
        },

        {
          label: "Worksite Management",
          name: "WORKSITE_MANAGEMENT",
          action: allSelected,
        },

        {
          label: "Asset Type Management",
          name: "ASSET_TYPE_MANAGEMENT",
          action: {
            view: true,
            edit: true,
            add: true,
          },
        },
        {
          label: "Asset Management",
          name: "ASSET_MANAGEMENT",
          action: allSelected,
        },
        {
          label: "User Type Management",
          name: "USER_TYPE_MANAGEMENT",
          action: {
            view: true,
            edit: true,
            add: true,
            deactivate: true,
          },
        },
        {
          label: "User Management",
          name: "USER_MANAGEMENT",
          action: allSelected,
        },
        {
          label: "Checklist Settings",
          name: "CHECKLIST_SETTINGS",
          action: {
            view: true,
            edit: true,
            add: true,
          },
        },
        {
          label: "System Settings",
          name: "SYSTEM_SETTINGS",
          action: {
            view: true,
            edit: true,
          },
        },
        {
          label: "Inspect Asset",
          name: "INSPECT_ASSET",
          action: {
            view: true,
          },
        },
        {
          label: "Device Information",
          name: "DEVICE_INFORMATION",
          action: {
            view: false,
          },
        },
        {
          label: "Activity Logs",
          name: "ACTIVITY_LOGS",
          action: {
            view: true,
          },
        },
      ],
    },
    {
      section: "TABLET",
      permissions: [
        {
          label: "Administrator Access",
          name: "ADMINISTRATOR_ACCESS",
          action: {
            view: true,
          },
        },
        {
          label: "Unlock Vehicle",
          name: "UNLOCK_VEHICLE",
          action: {
            view: true,
          },
        },
        {
          label: "Perform Inspections",
          name: "PERFORM_INSPECTIONS",
          action: {
            view: true,
          },
        },
      ],
    },
  ],
  MULTIPLE_WORKSITES: [
    {
      section: "DASHBOARD",
      permissions: [
        {
          label: "Reports",
          name: "REPORTS",
          action: {
            view: true,
          },
        },
        {
          label: "Analytics",
          name: "ANALYTICS",
          action: {
            view: true,
          },
        },

        {
          label: "Worksite Management",
          name: "WORKSITE_MANAGEMENT",
          action: {
            view: true,
            edit: true,
          },
        },

        {
          label: "Asset Type Management",
          name: "ASSET_TYPE_MANAGEMENT",
          action: {
            view: true,
            edit: true,
            add: true,
          },
        },
        {
          label: "Asset Management",
          name: "ASSET_MANAGEMENT",
          action: allSelected,
        },
        {
          label: "User Type Management",
          name: "USER_TYPE_MANAGEMENT",
          action: {
            view: false,
            edit: false,
            add: false,
            deactivate: false,
          },
        },
        {
          label: "User Management",
          name: "USER_MANAGEMENT",
          action: allSelected,
        },
        {
          label: "Checklist Settings",
          name: "CHECKLIST_SETTINGS",
          action: {
            view: true,
            edit: true,
            add: true,
          },
        },
        {
          label: "System Settings",
          name: "SYSTEM_SETTINGS",
          action: {
            view: true,
            edit: true,
          },
        },
        {
          label: "Inspect Asset",
          name: "INSPECT_ASSET",
          action: {
            view: true,
          },
        },
        {
          label: "Device Information",
          name: "DEVICE_INFORMATION",
          action: {
            view: false,
          },
        },
        {
          label: "Activity Logs",
          name: "ACTIVITY_LOGS",
          action: {
            view: false,
          },
        },
      ],
    },
    {
      section: "TABLET",
      permissions: [
        {
          label: "Administrator Access",
          name: "ADMINISTRATOR_ACCESS",
          action: {
            view: true,
          },
        },
        {
          label: "Unlock Vehicle",
          name: "UNLOCK_VEHICLE",
          action: {
            view: true,
          },
        },
        {
          label: "Perform Inspections",
          name: "PERFORM_INSPECTIONS",
          action: {
            view: true,
          },
        },
      ],
    },
  ],
  SINGLE_WORKSITE: [
    {
      section: "DASHBOARD",
      permissions: [
        {
          label: "Reports",
          name: "REPORTS",
          action: {
            view: true,
          },
        },
        {
          label: "Analytics",
          name: "ANALYTICS",
          action: {
            view: true,
          },
        },

        {
          label: "Worksite Management",
          name: "WORKSITE_MANAGEMENT",
          action: {
            view: true,
            edit: true,
          },
        },

        {
          label: "Asset Type Management",
          name: "ASSET_TYPE_MANAGEMENT",
          action: {
            view: true,
            edit: true,
            add: true,
          },
        },
        {
          label: "Asset Management",
          name: "ASSET_MANAGEMENT",
          action: allSelected,
        },
        {
          label: "User Type Management",
          name: "USER_TYPE_MANAGEMENT",
          action: {
            view: false,
            edit: false,
            add: false,
            deactivate: false,
          },
        },
        {
          label: "User Management",
          name: "USER_MANAGEMENT",
          action: allSelected,
        },
        {
          label: "Checklist Settings",
          name: "CHECKLIST_SETTINGS",
          action: {
            view: true,
            edit: true,
            add: true,
          },
        },
        {
          label: "System Settings",
          name: "SYSTEM_SETTINGS",
          action: {
            view: true,
            edit: true,
          },
        },
        {
          label: "Inspect Asset",
          name: "INSPECT_ASSET",
          action: {
            view: true,
          },
        },
        {
          label: "Device Information",
          name: "DEVICE_INFORMATION",
          action: {
            view: false,
          },
        },

        {
          label: "Login Records",
          name: "LOGIN_RECORDS",
          action: {
            view: false,
          },
        },
        {
          label: "Activity Logs",
          name: "ACTIVITY_LOGS",
          action: {
            view: false,
          },
        },
      ],
    },
    {
      section: "TABLET",
      permissions: [
        {
          label: "Administrator Access",
          name: "ADMINISTRATOR_ACCESS",
          action: {
            view: true,
          },
        },
        {
          label: "Unlock Vehicle",
          name: "UNLOCK_VEHICLE",
          action: {
            view: true,
          },
        },
        {
          label: "Perform Inspections",
          name: "PERFORM_INSPECTIONS",
          action: {
            view: true,
          },
        },
      ],
    },
  ],
  INDIVIDUAL_EMPLOYEE: [
    {
      section: "TABLET",
      permissions: [
        {
          label: "Perform Inspections",
          name: "PERFORM_INSPECTIONS",
          action: {
            view: true,
          },
        },
        {
          label: "Activity Logs",
          name: "ACTIVITY_LOGS",
          action: {
            view: false,
          },
        },
      ],
    },
  ],
};

export const tableHeader = [
  { name: "Field Name", textleft: "left" },
  { name: "Field Type", textleft: "left" },
  { name: "Required", textleft: "left" },
  { name: "Description", textleft: "left" },
];
