import styled from "styled-components";

export const IncidentTypeDiv = styled.div`
  div.ImpactButton {
    height: 27px;
    border-radius: 4px;
    line-height: 10px;
    width: 100% !important;
    padding: 0px;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }

  div.btn-border-High {
    border: 1px solid #dd0000;
  }

  div.btn-border-Mid {
    border: 1px solid #dd9000;
  }

  div.btn-border-Low {
    border: 1px solid #11a70b;
  }

  .highColor {
    color: #dd0000;
  }
  .midColor {
    color: #ddaa00;
  }
  .lowColor {
    color: #11a70b;
  }

  div.anim-button {
    line-height: 23px;
    position: relative;
    width: 118px !important;
    margin-left: 0px;
    font-size: 14px;
  }

  div.anim-button:hover .iconslide {
    transform: translateX(-85px);
  }

  div.anim-button:hover .text-a {
    display: none;
  }

  div.anim-button:hover .text-b {
    display: block;
    position: absolute;
    right: 10px;
    line-height: 23px;
    font-family: "Open Sans";
    font-weight: 600;
  }
`;

export const DivStyled = styled.div``;

export const DivSpanText = styled.div`
  float: left;
  margin-left: 10px;
  line-height: 23px;
  font-family: "Open Sans";
  font-weight: 600;
`;

export const IconClass = styled.div`
  fill: #dd0000;
  margin-left: 3px;
  float: right;
  width: 28px;
  margin-top: 2px;
  font-size: 20px !important;
  transition: transform 0.4s ease;
  transform: translateX(0px);
`;

export const DivSpanValue = styled.div`
  display: none;
`;

export const StyledSpan = styled.span`
  height: ${(props) => `calc(100vh - ${props.height}px)`};
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  clear: both;
  @media screen and (max-width: 800px) {
    font-size: ${({ theme }) => theme.fontSizes.font5} !important;
  }
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* semi-transparent white */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
