import styled from "styled-components";

const DatePickerFontStyle = (theme) => ({
  small: {
    fontSize: theme.fontSizes.font7,
    lineHeight: theme.lineHeights.line4,
    labelFontSize: theme.fontSizes.font8,
    paddingInput: "3px 24px 3px 24px",
    marginIcon: "8px 6px",
    iconFont: theme.fontSizes.font7,
    calFontHead: theme.fontSizes.font5,
    errorFontSize: theme.fontSizes.font7,
    optioFontSize: theme.fontSizes.font7,
    calDatePickWidth: "385px",
    calWidthAuto: "2.2rem",
    timePickerInput: "110px",
    timePickerInputWidth: "auto",
  },
  medium: {
    fontSize: theme.fontSizes.font7,
    lineHeight: theme.lineHeights.line4,
    labelFontSize: theme.fontSizes.font8,
    paddingInput: "5px 24px 5px 26px",
    marginIcon: "8px 6px",
    iconFont: theme.fontSizes.font6,
    calFontHead: theme.fontSizes.font4,
    errorFontSize: theme.fontSizes.font6,
    optioFontSize: theme.fontSizes.font6,
    calDatePickWidth: "400px",
    calWidthAuto: "2.3rem",
    timePickerInput: "120px",
    timePickerInputWidth: "90px",
  },
  large: {
    fontSize: theme.fontSizes.font6,
    lineHeight: theme.lineHeights.line4,
    labelFontSize: theme.fontSizes.font6,
    paddingInput: "7px 24px 7px 28px",
    marginIcon: "10px 6px",
    iconFont: theme.fontSizes.font5,
    calFontHead: theme.fontSizes.font4,
    errorFontSize: theme.fontSizes.font6,
    optioFontSize: theme.fontSizes.font6,
    calDatePickWidth: "415px",
    calWidthAuto: "2.4rem",
    timePickerInput: "140px",
    timePickerInputWidth: "92px",
  },
});

export const DateAndTime = styled.div`
  display: flex;
  width: 100%;
  .react-datepicker-wrapper {
    margin-right: 10px;
  }
  .react-datepicker--time-only {
    width: 100px !important;
    flex: 1;
  }
  input.inputTime {
    flex: 0;
  }

  .inputTime {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    border-radius: 4px;
    position: relative;
  }

  .inputTime::before {
    content: "\U";
    display: block;
    font-family: "siera-font-icons";
    font-size: ${({ theme }) => theme.fontSizes.font7};
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 0;
    transform: translateY(-50%);
  }

  .inputTime::-webkit-calendar-picker-indicator {
    background: transparent;
  }
  .inputTime:focus {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }

  .inputTime:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const DatePickDiv = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");

  select {
    border: 1px solid;
    :hover {
      border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    }
  }
  input {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].fontSize} !important;
    line-height: ${(props) => DatePickerFontStyle(props.theme)[props.size].lineHeight} !important;
    font-weight: 400;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    :placeholder {
      color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    }
    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    }
    &:active {
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    }
    &:focus {
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    }
    &:disabled {
      border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      pointer-events: none;
      opacity: 0.6;
    }
  }

  .react-datepicker__view-calendar-icon input {
    padding: ${(props) => DatePickerFontStyle(props.theme)[props.size].paddingInput};
  }

  .react-datepicker__calendar-icon {
    width: 14px;
    height: 14px;

    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  }

  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    border-radius: 2px;
    height: 20px;
    width: 20px;
    padding: 2px;
    font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].iconFont} !important;
    line-height: 1;
  }

  .react-datepicker__input-container .react-datepicker__calendar-icon {
    position: absolute;
    padding: initial;
    margin: ${(props) => DatePickerFontStyle(props.theme)[props.size].marginIcon};
    font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].iconFont} !important;
  }

  .react-datepicker {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    width: 300px;
    .react-datepicker__month-container {
      float: left;
      width: 100%;
    }
    :active {
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    }
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }

  .react-datepicker__day--today {
    border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    border-radius: 4px;
    /*:hover {
      background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
      color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    }*/
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .react-datepicker__month-dropdown-container,
  .react-datepicker__year-dropdown-container {
    position: relative;

    :after {
      border-bottom-style: solid;
      border-bottom-width: 1.5px;
      border-right-style: solid;
      border-right-width: 1.5px;
      content: "";
      display: inline-block;
      height: 10px;
      right: 14px;
      position: absolute;
      top: 10px;
      width: 10px;
      transform: rotate(45deg);
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    }
  }
  .react-datepicker {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  }

  .react-datepicker__header__dropdown {
    width: 100%;
    display: flex;
  }

  .react-datepicker__month-dropdown-container {
    flex: 1;
    margin: 0 0px;
    select {
      width: 90%;
      margin: auto;
      background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
      border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      height: 35px;
      border-radius: 4px;
      font-weight: 400;
      font-family: ${({ theme }) => theme.fontFamily.primaryFont};
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
      font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].optioFontSize} !important;
      padding: 0px 10px 0px 10px;
    }
  }

  .react-datepicker__day-names {
    .react-datepicker__day-name {
      color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
      font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].optioFontSize} !important;
    }
  }

  .react-datepicker__day-name {
    width: ${(props) => DatePickerFontStyle(props.theme)[props.size].calWidthAuto};
  }

  .react-datepicker__day {
    width: ${(props) => DatePickerFontStyle(props.theme)[props.size].calWidthAuto};
    text-align: right;
    padding-right: 5px;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].optioFontSize} !important;
  }

  .react-datepicker__year-dropdown-container {
    flex: 1;
    margin: 0 0px;
    select {
      background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
      width: 90%;
      margin: auto;
      border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      height: 35px;
      border-radius: 4px;
      font-family: ${({ theme }) => theme.fontFamily.primaryFont};
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
      font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].optioFontSize} !important;
      padding: 0px 10px 0px 10px;
    }
  }

  .react-datepicker__day--selected {
    border-radius: 0.3rem;
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  }

  .react-datepicker__month {
    margin: initial;
    /* background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};*/
  }

  .react-datepicker__day--disabled {
    border-radius: 0.3rem;
    /*background-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"}; */
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  }

  .react-datepicker__day--outside-month {
    opacity: 0;
  }

  .react-datepicker__current-month {
    display: none;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].calFontHead} !important;
    text-align: left;
    padding: 10px;
    margin-top: -10px;
  }

  .dateHeader {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].calFontHead} !important;
    text-align: left;
    padding: 10px 10px 0px 10px;
    font-weight: bold;
  }

  .react-datepicker__navigation {
    display: none;
  }

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }

  label {
    display: block;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    margin-bottom: 0rem;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].labelFontSize} !important;
    float: left;
    width: 100%;
    text-align: left;
    line-height: ${({ theme }) => theme.lineHeights.line6};
  }

  .react-datepicker-wrapper {
    float: left;
    width: 100%;
    /* margin-right: 10px;*/
  }

  .react-datepicker__input-time-container .react-datepicker-time__caption {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  }

  input.SieraTimeStyle {
    width: 100px;
    height: 34px;
    float: left;
    margin-right: 10px;
    padding-left: 5px;
    &:part(list) {
      background-color: yellow;
      color: red;
    }
    &:part(list-box) {
      background-color: yellow;
      color: red;
    }
  }

  input[type="time"]::part(list) {
    background-color: yellow;
    color: red;
  }

  input[type="time"]::part(list-box) {
    background-color: yellow;
    color: red;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    /* filter: invert(1); Use invert to change the color */
  }

  /* Optional: Change the background color to make the numbers more visible */
  input[type="time"] {
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    color: red; /* Change the color of the numbers */
    /* Add any other styles you need */
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    line-height: ${({ theme }) => theme.lineHeights.line2};
    padding: initial;
  }
`;

export const OnlyDatePicker = styled.div``;
export const DatePickerRange = styled.div`
  display: flex;
`;
export const DateRagneFrom = styled.div`
  /* flex: 0; */
  width: 50%;
  z-index: 4;
  input {
    /* width: 180px; */
    width: 100%;
    padding-right: 3px !important;
    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
    }
    &:active {
      border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
`;
export const DividerStyle = styled.div`
  flex: 0;
  align-self: end;
  font-size: ${({ theme }) => theme.fontSizes.font1} !important;
  font-weight: 100;
  position: relative;
  top: 5px;
  margin-left: 7px;
  margin-right: 7px;
`;
export const DatePickerRangeTime = styled.div`
  input {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  display: flex;
  .react-datepicker {
    width: ${(props) => DatePickerFontStyle(props.theme)[props.size].calDatePickWidth};
  }
  .dateHeader {
    padding: 1px 10px 0px 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }

  .react-datepicker__time-container {
    width: 86px;
  }
  .react-datepicker .react-datepicker__month-container {
    width: auto;
  }
  .react-datepicker__time-container {
    border-left: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }

  .react-datepicker-time__header {
    margin-top: 0;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    font-weight: bold;
    text-align: left;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].calFontHead} !important;
    text-transform: capitalize;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].optioFontSize} !important;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
`;
export const TimePicker = styled.div`
  input {
    width: ${(props) => DatePickerFontStyle(props.theme)[props.size].timePickerInput};
    padding-right: 3px !important;
  }
  .react-datepicker {
    width: ${(props) => DatePickerFontStyle(props.theme)[props.size].timePickerInputWidth};
  }
  .react-datepicker-time__header {
    font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].calFontHead} !important;
    text-align: left;
  }
  .react-datepicker__time-container {
    font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].optioFontSize} !important;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
`;
export const TimePickerRange = styled.div`
  input {
    width: ${(props) => DatePickerFontStyle(props.theme)[props.size].timePickerInput};
    padding-right: 3px !important;
  }
  .react-datepicker {
    width: ${(props) => DatePickerFontStyle(props.theme)[props.size].timePickerInputWidth};
  }
  .react-datepicker-time__header {
    font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].calFontHead} !important;
    text-align: left;
  }
  .react-datepicker__time-container {
    font-size: ${(props) => DatePickerFontStyle(props.theme)[props.size].optioFontSize} !important;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
`;

export const ShowData = styled.div`
  display: block;
  float: left;
  width: 100%;
  margin-top: 20px;
`;

export const CustomTimePickerContainer = styled.div``;
export const TimePickerOption = styled.div`
  .custom-time-picker {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .custom-time-picker select {
    padding: 4px;
    font-size: ${({ theme }) => theme.fontSizes.font6};
  }
`;
