import styled from "styled-components";

export const CSVButtonWrapper = styled.div`
  & button {
    float: right;
  }
  display: flex;
  align-items: end;
  height: 56px;
  float: right;
  @media (max-width: 768px) {
    margin-top: -56px;
  }
`;

export const CustomDropdown = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  box-shadow: 1px 1px 5px ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  border-radius: 4px;
  z-index: 2;
  right: 14px;
  top: 58px;
  width: max-content;
  @media (max-width: 768px) {
    top: 0px;
  }
`;

export const ClickWrapper = styled.div`
  cursor: pointer;
  & a {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    text-align: left;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    padding: 5px 15px;
    float: left;
    width: 100%;
    cursor: pointer;
  }
`;

export const CSVSEND = styled.div`
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  text-align: left;
  margin-top: 8px;
  cursor: pointer;
`;

export const OptionText = styled.div`
  color: ${({ isDisabled }) => (isDisabled ? "#9e9e9e" : "rgb(50, 50, 50)")};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  text-align: left;
  border-bottom: 1px solid rgba(204, 204, 204, 0.8);
  padding: 5px 15px;
  float: left;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
`;
