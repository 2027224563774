import React, { useContext } from "react";
import PropTypes from "prop-types";
import "../../assets/styles/styles.css";
import { ButtonWrapper } from "./Button.component.styles";
import { ThemeContext } from "styled-components";

const Button = (props) => {
  const { label, buttonType, showIcon, size, iconOnly, icon, iconClass, iconPosition, ...rest } = props;
  const themeContext = useContext(ThemeContext);

  const buttonStyles = {
    primary: {
      color: themeContext?.colors?.grayL7 || "#FFFFFF",
      backgroundColor: themeContext?.colors?.themePrimary || "#0d5fbe",
      background: `${
        themeContext?.colors?.themePrimaryDarker || "#004AA0"
      } radial-gradient(circle, transparent 1%, ${
        themeContext?.colors?.themePrimaryDarker || "#004AA0"
      } 1%) center/15000%`,
      borderDefault: `1px solid ${themeContext?.colors?.themePrimary || "#0d5fbe"}`,
      outline: `1px solid ${themeContext?.colors?.themePrimary || "#0d5fbe"}`,
      hoverColorText: "",
      disabledBackground: themeContext?.colors?.grayL5 || "#CCCCCC",
      disabledTextColor: themeContext?.colors?.grayL3 || "#676767",
      disabledBorder: `1px solid ${themeContext?.colors?.grayL5 || "#CCCCCC"}`,
    },
    secondary: {
      color: themeContext?.colors?.themePrimary || "#0d5fbe",
      backgroundColor: themeContext?.colors?.grayL7 || "#FFFFFF",
      background: `${
        themeContext?.colors?.themePrimary || "#0d5fbe"
      } radial-gradient(circle, transparent 1%, ${
        themeContext?.colors?.themePrimary || "#0d5fbe"
      } 1%) center/15000%`,
      borderDefault: `1px solid ${themeContext?.colors?.themePrimary || "#0d5fbe"}`,
      boxShadowOutlone: `0 0 0 1px  ${themeContext?.colors?.grayL7 || "#FFFFFF"}, 0 0 0 2px ${
        themeContext?.colors?.themePrimary || "#0d5fbe"
      }`,
      hoverColorText: themeContext?.colors?.grayL7 || "#FFFFFF",
      disabledBackground: themeContext?.colors?.grayL7 || "#FFFFFF",
      disabledTextColor: themeContext?.colors?.grayL3 || "#676767",
      disabledBorder: `1px solid ${themeContext?.colors?.grayL5 || "#CCCCCC"}`,
    },
    save: {
      color: "#FFFFFF",
      backgroundColor: themeContext?.colors?.greenPrimary || "#11A70B",
      background: `${themeContext?.colors?.greenHover || "#0D8908"} radial-gradient(circle, transparent 1%, ${
        themeContext?.colors?.greenHover || "#0D8908"
      }  1%) center/15000%`,
      borderDefault: `1px solid ${themeContext?.colors?.greenPrimary || "#11A70B"}`,
      outline: `1px solid ${themeContext?.colors?.greenPrimary || "#11A70B"}`,
      hoverColorText: themeContext?.colors?.grayL7 || "#FFFFFF",
      disabledBackground: themeContext?.colors?.grayL5 || "#CCCCCC",
      disabledTextColor: themeContext?.colors?.grayL3 || "#676767",
      disabledBorder: `1px solid ${themeContext?.colors?.grayL5 || "#CCCCCC"}`,
    },
    alert: {
      color: "#FFFFFF",
      backgroundColor: themeContext?.colors?.redPrimary || "#DD0000",
      background: `${
        themeContext?.colors?.redHover || "#B20000"
      }  # radial-gradient(circle, transparent 1%, ${
        themeContext?.colors?.redHover || "#B20000"
      } 1%) center/15000%`,
      borderDefault: `1px solid ${themeContext?.colors?.redPrimary || "#DD0000"}`,
      outline: `1px solid ${themeContext?.colors?.redPrimary || "#DD0000"}`,
      hoverColorText: themeContext?.colors?.grayL7 || "#FFFFFF",
      disabledBackground: themeContext?.colors?.grayL5 || "#CCCCCC",
      disabledTextColor: themeContext?.colors?.grayL3 || "#676767",
      disabledBorder: `1px solid ${themeContext?.colors?.grayL5 || "#CCCCCC"}`,
    },
    ghost: {
      color: themeContext?.colors?.grayL4 || "#9A9A9A",
      backgroundColor: themeContext?.colors?.grayL7 || "#FFFFFF",
      background: `${themeContext?.colors?.grayL6 || "#F2F2F2"} radial-gradient(circle, transparent 1%, ${
        themeContext?.colors?.grayL6 || "#F2F2F2"
      }  1%) center/15000%`,
      borderDefault: `1px solid ${themeContext?.colors?.grayL7 || "#FFFFFF"}`,
      outline: `1px solid ${themeContext?.colors?.themePrimary || "#0d5fbe"}`,
      hoverColorText: themeContext?.colors?.grayL1 || "#323232",
      disabledBackground: themeContext?.colors?.grayL7 || "#FFFFFF",
      disabledTextColor: themeContext?.colors?.grayL3 || "#676767",
      disabledBorder: `1px solid ${themeContext?.colors?.grayL5 || "#CCCCCC"}`,
    },
    small: {
      fontSize: themeContext?.fontSizes?.font8,
      heightIcon: "14px",
      widthIcon: "14px",
    },
    medium: {
      fontSize: themeContext?.fontSizes?.font7,
      heightIcon: "16px",
      widthIcon: "16px",
    },
    large: {
      fontSize: themeContext?.fontSizes?.font6,
      heightIcon: "20px",
      widthIcon: "20px",
    },
    /* This is for only icon which is pdf */
    iconPDF: {
      heightIcon: "37px",
      padding: " 0px 0px",
      fontSize: "0px",
      widthIcon: "37px",
      backgroundColor: "transparent",
      borderDefault: "0px",
      paddingIconBig: "0px 0px",
      colorPDF: themeContext?.colors?.PdfColor || "#B30B00",
      colorPDFHover: themeContext?.colors?.grayL7 || "#FFFFFF",
      backgroundColorHoverPDF: themeContext?.colors?.PdfColor || "#B30B00",
      disabledIconColorPDF: themeContext?.colors?.grayL5 || "#CCCCCC",
    },
    /* This is for only icon which is CSV */
    iconCSV: {
      heightIcon: "37px",
      padding: " 0px 0px",
      fontSize: "0px",
      widthIcon: "37px",
      backgroundColor: "transparent",
      borderDefault: "0px",
      paddingIconBig: "0px 0px",
      colorPDF: themeContext?.colors?.CsvColor || "#008000",
      colorPDFHover: themeContext?.colors?.grayL7 || "#FFFFFF",
      backgroundColorHoverPDF: themeContext?.colors?.CsvColor || "#008000",
      disabledIconColorPDF: themeContext?.colors?.grayL5 || "#CCCCCC",
    },
  };

  return (
    <ButtonWrapper
      buttonType={buttonType}
      size={size}
      iconOnly={iconOnly}
      buttonStyles={buttonStyles}
      iconPosition={iconPosition}
      title={!iconOnly && label}
      {...rest}
    >
      {iconPosition === "before" && (showIcon || iconOnly) && iconClass && (
        <i className={`icon icon-${iconClass}`} style={{ pointerEvents: "none" }} />
      )}
      {!iconOnly && label}
      {iconPosition === "after" && (showIcon || iconOnly) && iconClass && (
        <i className={`icon icon-${iconClass}`} style={{ pointerEvents: "none" }} />
      )}
    </ButtonWrapper>
  );
};

Button.propTypes = {
  /**
   *  What kind of button it is. Based on this a set of style is applied
   */
  buttonType: PropTypes.oneOf(["primary", "secondary", "save", "ghost", "alert", "iconPDF", "iconCSV"]),
  /**
   * Whether we want to show icon or not.
   */
  showIcon: PropTypes.bool,
  /**
   * Specify whether the Button should be disabled, or not
   */
  disabled: PropTypes.bool,
  /**
   * Do you want to show the icon only, without label?
   */
  iconOnly: PropTypes.bool,
  /**
   * Button label
   */
  label: PropTypes.string.isRequired,
  /**
   *  Provide an function to be called when the button element is clicked
   */
  onClick: PropTypes.func,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(["medium", "large", "small"]),
  /**
   * Position of the icon relative to the label.
   */
  iconPosition: PropTypes.oneOf(["before", "after"]),
};

Button.defaultProps = {
  buttonType: "primary",
  icon: false,
  iconOnly: false,
  size: "medium",
  iconPosition: "before",
};

export default Button;
