export const tableHeader = [
  /*   { name: "Account", feildName: "accountName" }, */
  { name: "Worksite", feildName: "worksite" },
  { name: "Asset Name", feildName: "assetName" },
  { name: "Asset Tag", feildName: "assetName" },
  { name: "SIERA.AI Products", feildName: "sieraProducts" },
  { name: "Last Seen", feildName: "", isCalculated: true },
];

export const paginationOptions = [
  { name: 10, value: 10 },
  { name: 25, value: 25 },
  { name: 50, value: 50 },
  { name: 100, value: 100 },
];

export const deviceInfo = [
  { label: "App Version", key: "appVersion" },
  { label: "S3 Version", key: "s3Version" },
  { label: "Android OS Version", key: "androidVersion" },
  { label: "Last Seen", key: "lastSeen", type: "lastSeen" },

  { label: "BLE Status", key: "bleStatus" },
  { label: "BLE MAC Address", key: "bleAddress" },
  { label: "Wi-Fi MAC Address", key: "wifiAddress" },
  { label: "Wi-Fi Strength", key: "wifiStrength" },

  { label: "LTE Connection Status", key: "lteStatus" },
  { label: "Battery Level", key: "batteryLevel", type: "percentage" },
  { label: "Last Settings Updated On", key: "lastSettingsUpdatedOn", type: "date" },
  { label: "Device Setup By", key: "deviceSetupBy", type: "deviceSetupBy" },
  { label: "Device Setup On", key: "deviceSetupOn", type: "date" },
];

export const assetDetails = [
  {
    label: "Last Inspection Report",
    key: "lastInspectionReport",
    iconName: "inspection-passed",
    type: "inspectionReport",
  },

  {
    label: "Last Impact Report",
    key: "lastImpactReport",
    iconName: "impact",
    mostRecentOf: ["lastLowImpactAt", "lastMediumImpactAt", "lastHighImpactAt"],
    type: "impactReport",
  },

  {
    label: "Last Pedestrian Near-Miss Report",
    key: "lastPedestrianDetectionAt",
    iconName: "pedestrian",
    type: "date",
    iconColor: "#DD0000",
  },
  {
    label: "Last Object Near-Miss Report",
    key: "lastObjectDetectionAt",
    iconName: "assets",
    type: "date",
    iconColor: "#DD9000",
  },

  {
    label: "Last Unauthorized Access Report",
    key: "lastUnauthorizedAccessAt",
    iconName: "unauthorized-user",
    type: "date",
    iconColor: "#323232",
  },
  { label: "Current Hour Meter", key: "lastHourMeterReading" },
  { label: "Accumulated Hour Meter Reading", key: "commulativeHourMeterReading" },
  { label: "Asset Added On", key: "AssetCreatedOn", type: "date" },
];

export const allDurationsArr = [
  { value: 5, label: "Last 5 minutes" },
  { value: 10, label: "Last 10 minutes" },
  { value: 15, label: "Last 15 minutes" },
  { value: 30, label: "Last 30 minutes" },
  { value: 60, label: "Last 60 minutes" },
  { value: 1, label: "Custom Range" },
];

const timeOptions = [
  { value: 1, label: "00:00 - 01:00" },
  { value: 2, label: "01:00 - 02:00" },
  { value: 3, label: "02:00 - 03:00" },
  { value: 4, label: "03:00 - 04:00" },
  { value: 5, label: "04:00 - 05:00" },
  { value: 6, label: "05:00 - 06:00" },
  { value: 7, label: "06:00 - 07:00" },
  { value: 8, label: "07:00 - 08:00" },
  { value: 9, label: "08:00 - 09:00" },
  { value: 10, label: "09:00 - 10:00" },
  { value: 11, label: "10:00 - 11:00" },
  { value: 12, label: "11:00 - 12:00" },
  { value: 13, label: "12:00 - 13:00" },
  { value: 14, label: "13:00 - 14:00" },
  { value: 15, label: "14:00 - 15:00" },
  { value: 16, label: "15:00 - 16:00" },
  { value: 17, label: "16:00 - 17:00" },
  { value: 18, label: "17:00 - 18:00" },
  { value: 19, label: "18:00 - 19:00" },
  { value: 20, label: "19:00 - 20:00" },
  { value: 21, label: "20:00 - 21:00" },
  { value: 22, label: "21:00 - 22:00" },
  { value: 23, label: "22:00 - 23:00" },
  { value: 24, label: "23:00 - 24:00" },
];

export const getTimeOptions = (selectedDate, currentDateTimeForTimeZone) => {
  let utcTime = 24;
  if (selectedDate.getDate() === new Date().getDate()) {
    utcTime = parseInt(new Date(currentDateTimeForTimeZone).getHours(), 10);
  }

  return timeOptions.filter((option) => option.value <= utcTime + 1);
};
