export default [
  { name: "CONFIG_CAMERA_DETECTION_ERROR", _id: "CONFIG_CAMERA_DETECTION_ERROR" },
  { name: "CONFIG_FORMAT_ERROR", _id: "CONFIG_FORMAT_ERROR" },
  { name: "CONFIG_TRANSFER_COMPLETE", _id: "CONFIG_TRANSFER_COMPLETE" },
  { name: "CONFIG_WRITE_ERROR", _id: "CONFIG_WRITE_ERROR" },
  { name: "DEVICE_SHUTDOWN", _id: "DEVICE_SHUTDOWN" },
  { name: "DFU_PACKET_ERROR", _id: "DFU_PACKET_ERROR" },
  { name: "DFU_TRANSFER_COMPLETE", _id: "DFU_TRANSFER_COMPLETE" },
  { name: "DFU_TRANSFER_ERROR", _id: " DFU_TRANSFER_ERROR" },
  { name: "DFU_WRITE_ERROR", _id: "DFU_WRITE_ERROR" },
  { name: "SYSTEM_REBOOT_COMPLETE", _id: "SYSTEM_REBOOT_COMPLETE" },
  { name: "SYSTEM_REBOOT_INITIATED", _id: "SYSTEM_REBOOT_INITIATED" },
  { name: "SYSTEM_STATUS_CAMERA_OUT", _id: "SYSTEM_STATUS_CAMERA_OUT" },
  { name: "SYSTEM_STATUS_DEBUG_VIEW_ENABLED", _id: "SYSTEM_STATUS_DEBUG_VIEW_ENABLED" },
  { name: "SYSTEM_STATUS_DISK_SPACE", _id: "SYSTEM_STATUS_DISK_SPACE" },
  { name: "SYSTEM_STATUS_ODOM_DEVICE_NOT_DETECTED", _id: "SYSTEM_STATUS_ODOM_DEVICE_NOT_DETECTED" },
  { name: "SYSTEM_STATUS_ODOM_ESTIMATION_ERROR", _id: "SYSTEM_STATUS_ODOM_ESTIMATION_ERROR" },
  { name: "SYSTEM_STATUS_SEGMENTATION_MODE_ENABLED", _id: "SYSTEM_STATUS_SEGMENTATION_MODE_ENABLED" },
  { name: "SYSTEM_STATUS_UNRECOGNISED_CAMERA", _id: "SYSTEM_STATUS_UNRECOGNISED_CAMERA" },
  { name: "SYSTEM_STATUS_USB_HUB_NOT_DETECTED", _id: "SYSTEM_STATUS_USB_HUB_NOT_DETECTED" },
  { name: "TYPE_CAMERA_DISCONNECTED_ERROR", _id: "TYPE_CAMERA_DISCONNECTED_ERROR" },
  { name: "TYPE_CAMERA_OCCLUDED_ERROR", _id: "TYPE_CAMERA_OCCLUDED_ERROR" },
  { name: "TYPE_CAMERA_ORIENTATION_ERROR", _id: "TYPE_CAMERA_ORIENTATION_ERROR" },
  { name: "TYPE_MULTI_CAMERA_ERRORS", _id: "TYPE_MULTI_CAMERA_ERRORS" },
];
