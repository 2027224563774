import styled from "styled-components";
export const TabMenuWrapper = styled.div`
  .TabStyleCSS {
    border-bottom: 2px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
  }

  .TabStyleCSS .nav-link.active {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background-color: initial;
    border-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"}
      ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;

    border-bottom: 3px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    font-weight: 500 !important;
  }

  .TabStyleCSS .nav-link.active:hover {
    border-bottom: 3px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }

  .TabStyleCSS .nav-link:hover {
    border-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"}
      ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    border-bottom: 3px solid ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }

  .TabStyleCSS .nav-link {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    padding-top: 10px;
    padding-bottom: 5px;
    cursor: pointer;
    display: inline-block;
    margin: 0px;
    margin-right: 20px;
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font5} !important;
    font-weight: 300;
  }
`;

export const FiltersWrapperTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 800px) {
    display: block;
    position: relative;
  }
`;

export const FilterWrap = styled.div`
  width: calc(100% - 50px);
   @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
