import styled from "styled-components";

const RadioButtonStyles  = (theme) => ({
  small: {
    fontSize: theme.fontSizes.font7,
    heightIcon: "16px",
    widthIcon: "16px",
  },
  medium: {
    fontSize: theme.fontSizes.font6,
    heightIcon: "20px",
    widthIcon: "20px",
  },
  large: {
    fontSize: theme.fontSizes.font5,
    heightIcon: "24px",
    widthIcon: "24px",
  },
});

export const RadioCircle = styled.div`
  float: none;
  position: relative;
  display: contents;
  
  &:before {
    content: "";
    background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    border-radius: 100%;
    border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    display: inline-block;
    width: ${(props) => RadioButtonStyles(props.theme)[props.size].widthIcon};
    height: ${(props) => RadioButtonStyles(props.theme)[props.size].heightIcon};
    margin-right: ${(props) => (props.label ? "12px" : "0px")};
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    vertical-align: text-bottom;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
    font-family: "Open Sans", sans-serif;
     background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }

  &:disabled {
    cursor: not-allowed; /* Show a not-allowed cursor when disabled */

    &:before {
      box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
      border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      background-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      opacity: 0.5; /* Optionally adjust opacity for better visual feedback */
      color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    }
  }
`;

export const RadioButtonWrapper = styled.div`
  margin-right: 0.5rem;
`;

export const RadioLabel = styled.label`
  font-family: "Open Sans", sans-serif;
  font-size: ${(props) => RadioButtonStyles(props.theme)[props.size].fontSize};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;

  + ${RadioLabel} {
    &:before {
      content: "";
      background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
      border-radius: 100%;
      border: 1px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
      display: inline-block;
      width: ${(props) => RadioButtonStyles(props.theme)[props.size].widthIcon};
      height: ${(props) => RadioButtonStyles(props.theme)[props.size].heightIcon};
      position: relative;
      margin-right: 12px;
      vertical-align: text-bottom;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease;
      font-family: "Open Sans", sans-serif;
    }
  }

  &:hover {
    + ${RadioLabel} {
      &:before {
        -webkit-box-shadow: 0px 0px 0px 3px ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"}50;
        -moz-box-shadow: 0px 0px 0px 3px ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"}50;
        box-shadow: 0px 0px 0px 3px ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"}50;
      }
    }
  }

  &:hover {
    &:checked {
      + ${RadioLabel} {
        &:before {
          outline: 3px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"}50;
        }
      }
    }
  }

  &:hover {
    &:checked {
      &:disabled {
        + ${RadioLabel} {
          &:before {
            outline: 0px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"}50;
          }
        }
      }
    }
  }

  &:checked {
    + ${RadioLabel} {
      &:before {
        background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
        &:disabled {
          background-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
        }
      }
    }
  }

  &:focus {
    + ${RadioLabel} {
      &:before {
        outline: 2px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
      }
    }
  }

  &:disabled {
    + ${RadioCircle} {
      &:before {
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
        border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
        background-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      }
    }
  }
  &:disabled {
    + ${RadioLabel} {
      color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      &:before {
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
        border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      }
    }
  }

  &:checked {
    &:disabled {
      + ${RadioLabel} {
        color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
        &:before {
          box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
          border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
          background-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
        }
      }
    }
  }

  + ${RadioLabel} {
    &:empty {
      &:before {
        margin-right: 0;
      }
    }
  }
`;
