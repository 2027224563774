
/* Rules for UI Theming

1. Margins and Padding

Rule: All margins and padding should be divisible by 4px.

Reason: Following a consistent spacing scale ensures visual alignment 
and makes it easier to manage layouts across the application.

Example:

margin: 4px;    <= right
padding: 8px;   <= right
margin: 5px;    <= wrong

left and right should be 16px margin for desktop 
input should have 8px margin

2. Font Sizes, Font Families, Line Heights and color or background 

Rule: All font sizes, font families, line heights and color or background should be defined through the theme.

Reason: Centralizing typography in the theme ensures consistency and allows for easy 
updates across the application.

Example:

const StyledText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font2};
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont}; 
  line-height: ${({ theme }) => theme.lineHeights.line1};
  color: ${({ theme }) => theme.colors.themePrimary};
`;

3. Icons

Rule: All icons should be sourced from Fontastic.

Reason: Using a single, consistent icon library simplifies asset management
and ensures a cohesive visual style.

Example:

<i class="icon icon-close"></i>

icon and text gap should be 8px

*/


export const baseTheme = {
  name: "base-theme",
  colors: {
    themePrimary: "#0D5FBE",
    themePrimaryDarker: "#004AA0",
    grayL1: "#323232",
    grayL2: "#434343",
    grayL3: "#676767",
    grayL4: "#9A9A9A",
    grayL5: "#CCCCCC",
    grayL6: "#F2F2F2",
    grayL7: "#FFFFFF",
    greenPrimary: "#11A70B",
    greenHover: "#0D8908",
    redPrimary: "#DD0000",
    redHover: "#B20000",
    linkColor: "#1070E0",
    orangePrimary: "#DD9000",
    orangeHover: "#C78200",
    PdfColor: "#B30B00",
    CsvColor: "#008000",
  },
  fontFamily: {
    primaryFont: "'Open Sans', sans-serif",
    secondaryFont: "Kanit, sans-serif",
  },
  fontSizes: {
    font1: "32px",
    font2: "28px",
    font3: "24px",
    font4: "20px",
    font5: "18px",
    font6: "16px",
    font7: "14px",
    font8: "12px",
  },
  lineHeights: {
    line1: "36px",
    line2: "32px",
    line3: "28px",
    line4: "24px",
    line5: "22px",
    line6: "20px",
    line7: "18px",
    line8: "16px",
  },
};

export const darkTheme = {
  name: "dark-theme",
  colors: {
    themePrimary: "#DEC500",
    themePrimaryDarker: "#f2df49",
    grayL1: "#FFFFFF",
    grayL2: "#F2F2F2",
    grayL3: "#CCCCCC",
    grayL4: "#9A9A9A",
    grayL5: "#676767",
    grayL6: "#434343",
    grayL7: "#323232",
    greenPrimary: "#11A70B",
    greenHover: "#07C100",
    redPrimary: "#DD0000",
    redHover: "#F50000",
    linkColor: "#1070E0",
    orangePrimary: "#DD9000",
    orangeHover: "#F8A100",
    PdfColor: "#B30B00",
    CsvColor: "#008000",
  },
  fontFamily: {
    primaryFont: "'Open Sans', sans-serif",
    secondaryFont: "Kanit, sans-serif",
  },
};
