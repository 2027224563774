import styled from "styled-components";

export const ModalContentWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 40px 20px;
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;
export const ModalTitle = styled.h3`
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
`;

export const SuccessIconWrapper = styled.div`
  float: none;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  text-align: center;
  display: inline-grid;
  border: 3px solid ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
`;

export const StyledButton = styled.button`
  margin-bottom: 10px;
  width: 160px !important;
`;

export const PrintMessageWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  margin-top: 15px;
  margin-bottom: 35px;
`;

export const QrWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconSuccess = styled.div`
  color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
  font-size: ${({ theme }) => theme.fontSizes.font2} !important;
  font-weight: bold;
  position: relative;
  top: 8px;
`;

export const FullWidthDiv = styled.div`
  display: block;
  margin-bottom: 14px;
`;
