import React, { useEffect, useState } from "react";
import { Switch, Route, useRouteMatch, Redirect, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Row, Col, Button } from "react-bootstrap";
import BreadCrumb from "../../../../components/BreadCrumb.component";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import {
  TabWrapper,
  Container,
  DropdownWrapper,
  ButtonWrapper,
  ContainerDiv,
  ContentWrapper,
  LeftSection,
  RightSection,
} from "./SystemSetting.component.styles";
import MenuBar from "./MenuBar.component";
import { getDropdownAccountList } from "../../../../redux/dropdownFilters/action";
import { setSettingsDropdownAccountId } from "../../../../redux/dropdownFilters/dropdownActions";
import Input from "../../../../components/Input";
import { useTranslation } from "react-i18next";
import AccountProfile from "../../Settings/AccountProfile";
import MFAAuth from "../../Settings/MFAAuth";
import UserProfile from "../../Settings/UserProfile/UserProfile.component";
import ChangePassword from "../../Settings/ChangePassword";
import PasswordPolicy from "../../Settings/PaawordPolicy/PasswordPolicy.component";
import ChangePin from "../../Settings/ChangePin/ChangePin.component";

function GeneralSetting({
  updateActiveNavItem,
  isSiteAdmin,
  dropdownAccountList,
  loginDetails,
  isSuperAdmin,
  isAccountAdmin,
  isAccountOwner,
  dropdownAccountId,
  setSettingsDropdownAccountId,
  getDropdownAccountList,
}) {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  useEffect(() => {
    updateActiveNavItem("systemSettings");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAccountAdmin || isAccountOwner || isSiteAdmin) {
      setSettingsDropdownAccountId(loginDetails && loginDetails.accountId);
    }
  }, [loginDetails]);

  useEffect(() => {
    if (!dropdownAccountList || dropdownAccountList?.length === 0) {
      getDropdownAccountList();
    }
  }, []);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <div className="settings">
      <BreadCrumb />
      <ContainerDiv>
        <ContentWrapper isSidebarVisible={isSidebarVisible}>
          <LeftSection className="sidebar-col" isSidebarVisible={isSidebarVisible}>
            {isSidebarVisible && (
              <TabWrapper>
                <MenuBar history={history} />
              </TabWrapper>
            )}
          </LeftSection>
          <RightSection isSidebarVisible={isSidebarVisible}>
            <ButtonWrapper className="absolute right-4" onClick={toggleSidebar}>
              {isSidebarVisible ? (
                <OverlayTrigger placement="top" overlay={<Tooltip id="expand-tooltip">Expand View</Tooltip>}>
                  <i className="icon icon-fullscreen-expand"></i>
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="collapse-tooltip">Collapse View</Tooltip>}
                >
                  <i className="icon icon-fullscreen-collapse"></i>
                </OverlayTrigger>
              )}
            </ButtonWrapper>
            <Switch>
              {isAccountOwner && <Route path={`${match.url}/account-profile`} component={AccountProfile} />}
              <Route path={`${match.url}/mfa-authentication`} component={MFAAuth} />
              <Route path={`${match.url}/password-policy`} component={PasswordPolicy} />

              <Route path={`${match.url}/user-profile`} component={UserProfile} />
              <Route path={`${match.url}/password`} component={ChangePassword} />
              <Route path={`${match.url}/pin`} component={ChangePin} />

              {isAccountOwner ? (
                <Redirect to={`${match.url}/account-profile`} />
              ) : (
                <Redirect to={`${match.url}/user-profile`} />
              )}
            </Switch>
          </RightSection>
        </ContentWrapper>
        <style jsx>{`
          @media (max-width: 768px) {
            .sidebar-col {
              width: 100% !important;
            }
            .content-col {
              width: 100% !important;
            }
          }
        `}</style>
      </ContainerDiv>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isSiteAdmin: state.user.isSiteAdmin,
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountAdmin: state.user.isAccountAdmin,
  isAccountOwner: state.user.isAccountOwner,
  dropdownAccountList: state.dropdownFilters?.accountList,
  dropdownAccountId: state.dropdownFilters?.settings?.accountId,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setSettingsDropdownAccountId: (accountId) => dispatch(setSettingsDropdownAccountId(accountId)),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralSetting));
