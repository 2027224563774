import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";

import {
  TableContainerDiv,
  RowsContainer,
  HeadingContainer,
  SubmitButtonDiv,
} from "./DownloadBulkLogsFilters.styles";
import Button from "../../../../components/Button/Button.component";
import DropdownComponent from "../../../../components/DropdownComponent/Dropdown.component";
import DateRangePickerFromTo from "../../../../components/DatePicker.component";
import { getDropdownWorksiteList, getDropdownAssetList } from "../../../../redux/modalDropdownFilters/action";
import { apiCall } from "../../../../utils/apiCall";
import { errorCodes } from "./constants";

const DownloadBulkLogsFilters = ({
  isSuperAdmin,
  dropdownAccountList,
  getDropdownWorksiteList,
  worksiteList,
  getDropdownAssetList,
  assetList,
  userData,
  setShowDownloadBulkLog,
  setDownloadBulkLogsSuccess,
}) => {
  const { t } = useTranslation();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedWorksite, setSelectedWorksite] = useState({ value: null, label: "All" });
  const [selectedAsset, setSelectedAsset] = useState({ value: null, label: "All" });
  const [selectedErrorDesc, setSelectedErrorDesc] = useState({
    label: "All",
    value: null,
  });
  const [worksitesListWithAll, setWorksitesListWithAll] = useState([]);
  const [assetListWithAll, setAssetListWithAll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedAccount?.value) {
      getDropdownWorksiteList(selectedAccount?.value);
    }
  }, [selectedAccount?.value, getDropdownWorksiteList]);

  useEffect(() => {
    getDropdownAssetList(selectedAccount?.value, selectedWorksite?.value, null);
  }, [selectedWorksite, getDropdownAssetList, selectedAccount?.value]);

  const [startDate, setStartDate] = useState(moment().subtract(7, "days").toDate());
  const [endDate, setEndDate] = useState(moment().toDate());

  const onDateChange = (date) => {
    setStartDate(date.startDate);
    setEndDate(date.endDate);
  };

  const submitHandler = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      const resp = await apiCall(`/asyncreq/s3logs/csvReport`, {
        method: "POST",
        data: {
          accountId: selectedAccount.value,
          siteId: selectedWorksite?.value,
          assetId: selectedAsset.value,
          errorDesc: selectedErrorDesc.value,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          createdBy: userData?._id,
          emailTo: [userData?.email],
        },
      });

      if (resp.data.success) {
        setShowDownloadBulkLog(false);
        setDownloadBulkLogsSuccess(true);
      }
    } catch (error) {
      console.error("Error fetching login records:", error);
    } finally {
      // Ensure the loading state is reset
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (worksiteList?.length > 0 && worksiteList?.[0]?._id !== -1) {
      const optionListTemp = worksiteList.map((option) => ({
        label: option.name,
        value: option._id,
      }));
      optionListTemp.unshift({ value: null, label: "All" });
      setWorksitesListWithAll(optionListTemp);
    } else {
      setWorksitesListWithAll([{ value: null, label: "All" }]);
    }
  }, [worksiteList]);

  useEffect(() => {
    if (assetList?.length > 0 && assetList?.[0]?._id !== -1) {
      const optionListTemp = assetList.map((option) => ({
        label: option.assetName,
        value: option._id,
      }));

      optionListTemp.unshift({ value: null, label: "All" });
      setAssetListWithAll(optionListTemp);
    } else {
      setAssetListWithAll([{ value: null, label: "All" }]);
    }
  }, [assetList]);

  return (
    <>
      <TableContainerDiv>
        <HeadingContainer>
          <Trans>S3 Error Logs</Trans>
        </HeadingContainer>

        <RowsContainer className="userTypeDetailsHeight">
          <Row className="p-4">
            {isSuperAdmin && (
              <Col md={6} className="mb-2">
                <DropdownComponent
                  label={t("Account")}
                  placeholder={"Select an Account"}
                  options={dropdownAccountList
                    ?.map((data) => ({
                      value: data._id,
                      label: data.companyName,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  value={selectedAccount}
                  handleFilter={(selectedOption) => {
                    setSelectedAccount(selectedOption);
                  }}
                  errorMessage={t("This field is required")}
                />
              </Col>
            )}

            <Col md={6} className="mb-2">
              <DropdownComponent
                label={"Worksite"}
                placeholder={"Select a worksite"}
                options={worksitesListWithAll}
                value={selectedWorksite}
                handleFilter={(selectedWorksite) => {
                  setSelectedWorksite(selectedWorksite);
                }}
                errorMessage={t("This field is required")}
              />
            </Col>

            <Col md={6} className="mb-2">
              <DropdownComponent
                label={"Asset"}
                placeholder={"Select an asset"}
                options={assetListWithAll}
                value={selectedAsset}
                handleFilter={(selectedAsset) => {
                  setSelectedAsset(selectedAsset);
                }}
              />
            </Col>

            <Col md={6} className="mb-2">
              <DropdownComponent
                label={"Error Description"}
                placeholder={"Select an error description"}
                options={errorCodes}
                value={selectedErrorDesc}
                handleFilter={(selectedErrDesc) => {
                  setSelectedErrorDesc(selectedErrDesc);
                }}
              />
            </Col>
            <Col md={12} className="mb-2 calender">
              <DateRangePickerFromTo
                setDate={(date) => onDateChange(date)}
                startDate={startDate}
                endDate={endDate}
                minDate={moment().subtract(30, "days").toDate()}
                size="small"
              />
            </Col>
          </Row>

          <SubmitButtonDiv>
            <Button
              iconClass="save"
              onClick={submitHandler}
              showIcon
              buttonType="primary"
              disabled={!selectedAccount?.value || isLoading}
              isLoading={isLoading}
              label={
                isLoading ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  t("Submit")
                )
              }
            />
          </SubmitButtonDiv>
        </RowsContainer>
      </TableContainerDiv>
    </>
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
  worksiteList: state.modalDropdownFilters.worksiteList,
  getWorksiteListSuccess: state.modalDropdownFilters.getWorksiteListSuccess,
  getWorksiteListLoading: state.modalDropdownFilters.getWorksiteListLoading,
  getWorksiteListFailed: state.modalDropdownFilters.getWorksiteListFailed,

  assetList: state.modalDropdownFilters.assetList,
  getAssetListSuccess: state.modalDropdownFilters.getAssetListSuccess,
  getAssetListLoading: state.modalDropdownFilters.getAssetListLoading,
  getAssetListFailed: state.modalDropdownFilters.getAssetListFailed,
  userData: state.user.loginDetails.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getDropdownWorksiteList: (accountId) => dispatch(getDropdownWorksiteList(accountId)),
  getDropdownAssetList: (accountId, worksiteId, assetTypeId) =>
    dispatch(getDropdownAssetList(accountId, worksiteId, assetTypeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadBulkLogsFilters);
