import styled from "styled-components";

export const HourMeterWrapper = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");

  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  float: left;
  text-align: center;
  float: left;
  text-align: center;
  width: 100%;
  align-items: center;
  align-content: center;
  align-self: center;
  display: flex;
  margin-bottom: 20px;

  input {
    height: 68px;
    line-height: 48px;
    border: 1px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    float: left;
    letter-spacing: 4px;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};

    :active {
      border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }

    :focus-visible {
      border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }

    :disabled {
      border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
    }

    :focus {
      /* outline: 2px solid ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
      border: 2px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
      box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};  */
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const ErrorText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font7};
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
`;

export const StyledInput = styled.input`
  width: 250px;
  margin: auto;
  ${({ isFocused }) => (isFocused ? "text-align: right;" : "text-align: center;")}
  ${({ fontSize }) => `font-size: ${fontSize}px !important;`}
  ${({ pointerEvents }) => (pointerEvents ? "pointer-events: none;" : "pointer-events: auto;")}
`;
