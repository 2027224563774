import React from "react";

import styled from "styled-components";
export const MarketinSlider = styled.div`
  height: 100%;
  .carousel .carousel-control-next-icon {
    width: 30px;
    height: 30px;
    margin-left: 133px;
  }

  .carousel-inner {
    height: 100%;
  }

  .carousel {
    text-align: center;
    height: 100%;
  }

  .carousel .carousel-control-prev-icon {
    width: 30px;
    height: 30px;
    margin-right: 133px;
  }

  .carousel .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }

  .carousel-item {
    height: calc(100vh - 110px);
    @media (max-width: 768px) {
      height: auto;
    }
  }

  .primary-button {
    border: none;
    border-radius: 20px;
    background-color: #f4ae33;
    color: #323232;
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
    &:hover {
      background: #f4ae33;
      color: #323232;
    }
  }

  .secondary-button {
    border: none;
    border-radius: 20px;
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  }

  .primary-button-yellow {
    background-color: yellow;
    color: white;
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  }

  .primary-button-blue {
    background-color: blue;
    color: black;
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  }
`;

export const ImgWrapper = styled.img`
  max-width: 100%;
  /*max-height: 100%;*/
  background-position: center;
  background-size: cover;
  background-origin: content-box;
  @media screen and (min-width: 1512px) and (max-width: 1512px) and (min-height: 858px) and (max-height: 858px) {
    height: 100%;
  }
`;
