import React, { useState, useEffect } from "react";
import Slider from "../camSettingView/SCZSlider";
import { ReactComponent as SCZForkLiftSideView } from "../../../../../../assets/CameraSettings/Forklift_Sideview.svg";
import { ReactComponent as ForkliftBack } from "../../../../../../assets/CameraSettings/Forklift_Back.svg";
import { ReactComponent as ForkliftFront } from "../../../../../../assets/CameraSettings/Forklift_Front.svg";
import { ReactComponent as ForkliftSideviewReverse } from "../../../../../../assets/CameraSettings/Forklift_Sideview_Reverse.svg";
import cameraHeightViewCss from "../../CameraSliderView/CameraHeightView.module.css";

const calculateHeightData = (isFeet, currentInputValue) => {
  return [
    {
      type: "pos4",
      min: isFeet ? parseFloat(currentInputValue.pos6) + 1.64 : parseInt(currentInputValue.pos6) + 500,
      max: isFeet ? 13.12 : 4000,
    },
    {
      type: "pos5",
      min: isFeet ? 1.64 : 500,
      max: isFeet ? parseFloat(currentInputValue.pos6) - 1.64 : parseInt(currentInputValue.pos6) - 500,
    },
    { type: "pos6", min: isFeet ? 4.92 : 1500, max: isFeet ? 9.84 : 3000 },
  ];
};

const {
  dot_line_1,
  dot_line_2,
  dot_line_3,
  dot_line_4,
  dot_line_5,
  dot_line_6,
  dot_line_7,
  forklift_height,
  container,
  square_red,
  parent,
  canvas_position,
  forklift_height_back_rear,
  forklift_height_reverse,
} = cameraHeightViewCss;

const SCZCameraHeightView = (props) => {
  const {
    obstacle,
    inputValue,
    setInputValue,
    value,
    setValue,
    s3CameraForm,
    selectedCamera,
    getCameraRawObject,
    forkliftClass,
    setTabKey,
    unitType,
    isFeet,
    s3SettingsUntouchedObject,
    camIndex,
  } = props;
  const currentInputValue = { ...inputValue[obstacle] };
  const [heightDataConst, setHeightDataConstant] = useState(calculateHeightData(false, currentInputValue));
  const [heightDataConstFeet, setHeightDataConstantFeet] = useState(
    calculateHeightData(true, currentInputValue)
  );

  useEffect(() => {
    const currentInputValue = { ...inputValue[obstacle] };
    setHeightDataConstant(calculateHeightData(false, currentInputValue));
    setHeightDataConstantFeet(calculateHeightData(true, currentInputValue));
  }, [inputValue]);

  let canvas = document.getElementById("sczMyCanvasHeight");
  useEffect(() => {
    if (canvas) {
      return;
    }
    canvas = document.getElementById("sczMyCanvasHeight");
  }, [value]);

  useEffect(() => {
    let tempPositionPedestrian = {};
    let tempPositionObject = {};
    let newS3CameraForm = { ...s3CameraForm };
    newS3CameraForm[selectedCamera] = getCameraRawObject(
      { number: selectedCamera, name: forkliftClass },
      inputValue
    );

    let currentHeightConst = isFeet ? heightDataConstFeet : heightDataConst;
    currentHeightConst.map((data) => {
      let { min, max, type } = data;
      let newValue = inputValue?.["sczPedestrian"]?.[type];
      if (newValue <= min) {
        newValue = min;
      }
      if (!newValue) {
        newValue = min;
      }
      if (newValue > max) {
        newValue = max;
      }
      if (type === "pos5") {
        newValue = Math.abs(newValue);
      }
      newValue = (newValue - min) / ((max - min) / 50);
      tempPositionPedestrian[type] = newValue;
    });
    currentHeightConst.map((data) => {
      let { min, max, type } = data;
      let newValue = inputValue?.["sczObject"]?.[type];
      if (newValue <= min) {
        newValue = min;
      }
      if (!newValue) {
        newValue = min;
      }
      if (newValue > max) {
        newValue = max;
      }
      newValue = (newValue - min) / ((max - min) / 50);
      tempPositionObject[type] = newValue;
    });
    let newValue = { ...value };
    let pos4Object = tempPositionObject?.pos4;
    let pos4Pedestrian = tempPositionPedestrian?.pos4;
    if (isNaN(pos4Object) || isNaN(pos4Pedestrian)) {
      return;
    }
    newValue["sczPedestrian"] = { ...tempPositionPedestrian };
    newValue["sczObject"] = { ...tempPositionObject };

    setValue({ ...newValue });
  }, [inputValue]);

  const rangeSlider1 = document.getElementById("scz-rs-range-line-4");
  const rangeBullet1 = document.getElementById("scz-rs-bullet-4");
  const rangeInput1 = document.getElementById("scz-rs-input-4");
  const rangeSlider2 = document.getElementById("scz-rs-range-line-5");
  const rangeBullet2 = document.getElementById("scz-rs-bullet-5");
  const rangeInput2 = document.getElementById("scz-rs-input-5");

  useEffect(() => {
    let { pos4, pos5 } = value[obstacle];

    if (isNaN(pos4)) {
      return;
    }
    const ctx = canvas?.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.moveTo(0, 244);
    let bulletPosition1 = pos4 / rangeSlider1?.max;
    let bulletPosition2 = pos5 / rangeSlider2?.max;

    if (rangeBullet1) {
      rangeBullet1.style.left = bulletPosition1 * 90 + "px";
      ctx.lineTo(pos4 * 1.72 + 12, 235 - pos4 * 1.8);
      ctx.lineTo(80 - pos5 * 1.34, 325 - pos5 * 1.4);
      ctx.lineTo(0, 244);
      ctx.closePath();
      ctx.fillStyle = "rgb(14, 95, 190, 0)";
      ctx.strokeStyle = "rgb(14, 95, 190, 0)";
      ctx.stroke();
      ctx.fill();
    }
    if (rangeBullet2) {
      rangeBullet2.style.left = bulletPosition2 * 70 + "px";
    }

    if (rangeInput1) {
      rangeInput1.style.left = bulletPosition1 * 90 + "px";
    }
    if (rangeInput2) {
      rangeInput2.style.left = bulletPosition2 * 70 + "px";
    }
  }, [value, inputValue, obstacle, selectedCamera]);

  const obstacleInLowerCase = obstacle.replace("scz", "").toLowerCase();
  const unTouchedSpeedControlObj =
    s3SettingsUntouchedObject?.[camIndex]?.speedControlZones?.[obstacleInLowerCase];

  return (
    <div style={{ postion: "relative" }} className="camera_height_style">
      <div className="width-text" style={{ top: "264px", width: "356px" }}>
        <p>
          Move the slider up or down to adjust the values.
          <br />
          Camera Height can be adjusted in the Near-Miss Detection settings.
        </p>
      </div>
      <canvas id="sczMyCanvasHeight" className={canvas_position} width="310" height="270"></canvas>
      {forkliftClass?.split("-")[0] === "rear" && (
        <SCZForkLiftSideView
          // src={SCZForkLiftSideView}
          style={{ width: "280px", height: "100px" }}
          className={forklift_height}
          alt="Forklift Side View"
        />
      )}
      {forkliftClass?.split("-")[0] === "front" && (
        <img
          src={ForkliftSideviewReverse}
          style={{ width: "280px", height: "100px" }}
          className={forklift_height_reverse}
          alt="Forklift Side View Reverse"
        />
      )}
      {forkliftClass === "right" && (
        <img
          src={ForkliftBack}
          style={{ width: "280px", height: "100px" }}
          className={forklift_height_back_rear}
          alt="Forklift Back View"
        />
      )}
      {forkliftClass === "left" && (
        <img
          src={ForkliftFront}
          style={{ width: "280px", height: "100px" }}
          className={forklift_height_back_rear}
          alt="Forklift Front View"
        />
      )}
      <div className={square_red} />
      <div className={dot_line_1} />
      <div className={dot_line_2} />
      <div className={dot_line_3} />
      <div className={dot_line_4} />
      <div className={dot_line_5} />
      <div className={dot_line_6} />
      <div className={dot_line_7} />

      <Slider
        unitType={unitType}
        obstacle={obstacle}
        inputValue={inputValue}
        setInputValue={setInputValue}
        setValue={setValue}
        value={value}
        type="pos6"
        min={isFeet ? 4.92 : 1500}
        max={isFeet ? 9.84 : 3000}
        untouchedValueinMM={unTouchedSpeedControlObj?.middle}
        isSliderDisabled={true}
      />
      <div className={container + " " + parent}>
        <Slider
          unitType={unitType}
          obstacle={obstacle}
          inputValue={inputValue}
          setInputValue={setInputValue}
          setValue={setValue}
          value={value}
          type="pos4"
          min={isFeet ? heightDataConstFeet[0].min : heightDataConst[0].min}
          max={isFeet ? 13.12 : 4000}
          setTabKey={setTabKey}
          untouchedValueinMM={unTouchedSpeedControlObj?.top}
        />
        <Slider
          unitType={unitType}
          obstacle={obstacle}
          inputValue={inputValue}
          setInputValue={setInputValue}
          setValue={setValue}
          value={value}
          type="pos5"
          min={isFeet ? 1.64 : 500}
          max={isFeet ? heightDataConstFeet[1].max : heightDataConst[1].max}
          setTabKey={setTabKey}
          untouchedValueinMM={unTouchedSpeedControlObj?.bottom}
        />
      </div>
      <div className="triangle_down"></div>
    </div>
  );
};

export default SCZCameraHeightView;
