import styled from "styled-components";

const searchSizeStyle = (theme, size) => {
  const sizes = {
    small: {
      wrapHeight: "32px",
      WrapMarTop: "5px",
      WrapHeightDef: "55px",
      WrapMarTopDef: "auto",
      fontSize: theme.fontSizes.font7,
      fontSizeDef: theme.fontSizes.font6,
    },
    medium: {
      wrapHeight: "36px",
      WrapMarTop: "1px",
      WrapHeightDef: "56px",
      WrapMarTopDef: "auto",
      fontSize: theme.fontSizes.font5,
      fontSizeDef: theme.fontSizes.font6,
    },
    large: {
      wrapHeight: "40px",
      WrapMarTop: "-3px",
      WrapHeightDef: "56px",
      WrapMarTopDef: "auto",
      fontSize: theme.fontSizes.font4,
      fontSizeDef: theme.fontSizes.font6,
    },
  };

  return sizes[size] || sizes.medium; 
};

export const LableWrapper = styled.div`
  float: left;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.font5} !important;
  line-height: ${({ theme }) => theme.lineHeights.line1};
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SearchIconSVG = styled.div`
  height: auto;
  width: 20px;
  position: relative;

  &:before {
    content: ${({ isSearchText, isIcon }) => (isSearchText ? "'L'" : isIcon ? "'L'" : "'c'")};
    font-family: "siera-font-icons";
    display: block;
    color: ${({ isSearchText, isIcon, theme }) =>
      isSearchText ? (isIcon ? theme.colors.themePrimary || "#0D5FBE" : theme.colors.grayL7 || "#FFFFFF") : isIcon ? theme.colors.grayL3 || "#676767" : theme.colors.grayL1 || "##323232"};
  }
  font-size: ${({ theme }) => theme.fontSizes.font5} !important;
  z-index: 1;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  @media (max-width: 768px) {
    height: auto;
  }
`;

export const BorderBottom = styled.div`
  background: ${({ isSearchText, theme }) =>
    isSearchText ? "1px solid " + theme.colors.grayL7 || "#FFFFFF" : "transparent"};
  position: absolute;
  z-index: 1;
  width: 97%;
  left: 0;
  right: 0;
  margin: auto;
  height: 1px;
  margin-top: 49px;
`;

export const SearchSubmit = styled.div`
  transition: all 0.2s ease-in-out;
  background-color: ${({ isIcon, backgroundColor, theme }) =>
    isIcon ? theme.colors.grayL6 || "#F2F2F2" : backgroundColor || "transparent"};
  border: none;
  float: right;
  position: relative;
  width: ${({ isIcon, width }) => (isIcon ? "42px" : width || "2rem")};
  z-index: 2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    margin-left: ${({ isIcon, marginLeft }) => (isIcon ? "initial" : marginLeft || "initial")};
  }
  @media (max-width: 768px) {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
  }
`;

export const SearchBoxWrapper = styled.div`
  background-color: ${({ isIcon, backgroundColor, theme }) =>
    isIcon ? theme.colors.grayL7 || "#FFFFFF" : backgroundColor || theme.colors.grayL6 || "#F2F2F2"};
  overflow: hidden;
  border-radius: ${({ isIcon, borderRadius }) =>
    isIcon ? "4px 4px 4px 4px" : borderRadius || "4px 4px 0px 0px"};
  display: inline-block;
  float: right;
  position: relative;
  width: 100%;
  margin-top: ${({ isIcon, marginTop, size, theme }) =>
    isIcon
      ? searchSizeStyle(theme, size).WrapMarTop
      : marginTop || searchSizeStyle(theme, size).WrapMarTopDef};
  height: ${({ isIcon, height, size, theme }) =>
    isIcon ? searchSizeStyle(theme, size).wrapHeight : height || searchSizeStyle(theme, size).WrapHeightDef};
  @media (max-width: 768px) {
    border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
    border-radius: 4px;
    height: 36px;
    margin-bottom: 10px;
  }

  &:focus-within {
    ${({ isIcon, width }) =>
      isIcon &&
      `width: ${width || "100%"};
      background: transparent;
      transition: width 0.5s ease-in-out;
    `}
  }

  input {
    position: absolute;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    border-radius: ${({ isIcon, borderRadius }) =>
      isIcon ? "4px 4px 4px 4px" : borderRadius || "4px 4px 0px 0px"};
    right: 0;
    transition: all 0.5s ease-in-out;
    background-color: ${({ isIcon, backgroundColor, theme }) =>
      isIcon ? theme.colors.grayL7 || "#FFFFFF" : backgroundColor || theme.colors.themePrimary || "#0D5FBE"};
    border: ${({ isIcon, border, theme }) =>
      isIcon ? "1px solid " + theme.colors.grayL5 || "#CCCCCC" : border || "none"};
    border-bottom: ${({ isIcon, borderBottom, theme }) =>
      isIcon
        ? "1px solid " + theme.colors.grayL5 || "#CCCCCC"
        : borderBottom || "1px solid " + theme.colors.grayL7 || "#FFFFFF"};
    box-sizing: border-box;
    color: ${({ isIcon, color, theme }) =>
      isIcon ? theme.colors.grayL3 || "#676767" : color || theme.colors.grayL7 || "#FFFFFF"};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    font-size: ${({ isIcon, fontSize, size, theme }) =>
      isIcon ? searchSizeStyle(theme, size).fontSize : fontSize || searchSizeStyle(theme, size).fontSizeDef};
    line-height: ${({ theme }) => theme.lineHeights.line5};
    font-weight: 300;
    z-index: ${({ isSearchText }) => (isSearchText ? 1 : 3)};
    padding-left: 14px;
    padding-right: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    width: ${({ isSearchText }) => (isSearchText ? "100%" : "35px")};

    opacity: ${({ isSearchText }) => (isSearchText ? 1 : 0)};

    &::before {
      content: "";
      position: absolute;
      border-bottom: 1px solid ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    }

    @media (max-width: 768px) {
      position: relative;
      transition: initial;
      width: ${({ isSearchText }) => (isSearchText ? "100%" : "100%")};
      opacity: ${({ isSearchText }) => (isSearchText ? 1 : 1)};
      background-color: white;
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
      padding-left: 10px;
    }

    &::after {
      content: "";
      position: absolute;
      border-bottom: 1px solid ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    }

    &:focus {
      cursor: text;
      opacity: 1;
      outline: none;
      width: 100%;
      z-index: 1;
    }

    &:focus ~ ${SearchSubmit} ${SearchIconSVG} {
      &:before {
        content: ${({ isSearchText, isIcon }) =>
          isSearchText ? (isIcon ? "'L'" : "'L'") : isIcon ? "'c'" : "'c'"};
        color: ${({ isSearchText, isIcon, theme }) =>
          isSearchText ? (isIcon ? theme.colors.themePrimary || "#0D5FBE" : theme.colors.grayL1 || "##323232") : isIcon ? theme.colors.grayL3 || "#676767" : theme.colors.grayL7 || "#FFFFFF"};
      }

      @media (max-width: 768px) {
        /* filter: invert(1); */
        &:before {
          content: ${({ isSearchText, isIcon }) =>
            isSearchText ? (isIcon ? "'L'" : "'L'") : isIcon ? "'c'" : "'L'"};
          color: ${({ isSearchText, isIcon }) =>
            isSearchText ? (isIcon ? "blue" : "black") : isIcon ? "grey" : "blue"};
        }
      }
    }

    &:focus ~ ${SearchSubmit} {
      background-color: transparent;
    }

    &::placeholder {
      color: ${({ isIcon, color, theme }) =>
        isIcon ? theme.colors.grayL3 || "#676767" : color || theme.colors.grayL7 || "#FFFFFF"};
      @media (max-width: 768px) {
        color: ${({ isIcon, color, theme }) =>
          isIcon ? theme.colors.grayL1 || "#323232" : color || theme.colors.grayL1 || "#323232"};
      }
    }

    &:focus ~ ${BorderBottom} {
      background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    }
  }
`;
