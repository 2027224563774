import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DateRangePicker from "./DatePicker.component"; // Import the DateRangePicker component
import { Col, Row } from "react-bootstrap";
import Button from "../Button/Button.component";
import styled from "styled-components";
const IconButton = styled.div`
  display: flex;
  align-items: end;
  height: 56px;
`;
const DashSign = styled.div`
  flex: 0;
  height: 30px;
  position: relative;
  right: 18px;
  @media (max-width: 768px) {
    right: auto;
  }
`;
const ButtonDiv = styled.div``;
const ReactDateRange = ({
  filterState,
  setDate,
  startDate,
  endDate,
  startTime,
  endTime,
  dateDisable,
  timeDisable,
  confirmDateTimeChange,
  dateTimeChange,
  callback,
  maxDate,
}) => {
  const [localStartDate, setLocalStartDate] = useState(startDate ? moment(startDate).toDate() : new Date());
  const [localEndDate, setLocalEndDate] = useState(endDate ? moment(endDate).toDate() : new Date());

  const [localStartTime, setLocalStartTime] = useState(
    startTime ? moment(startTime, "HH:mm").toDate() : new Date()
  );
  const [localEndTime, setLocalEndTime] = useState(endTime ? moment(endTime, "HH:mm").toDate() : new Date());

  useEffect(() => {
    setLocalStartDate(startDate ? moment(startDate).toDate() : new Date());
    setLocalEndDate(endDate ? moment(endDate).toDate() : new Date());
    setLocalStartTime(startTime ? moment(startTime, "HH:mm").toDate() : new Date());
    setLocalEndTime(endTime ? moment(endTime, "HH:mm").toDate() : new Date());
  }, [startDate, endDate, startTime, endTime]);

  const { t } = useTranslation();

  const applyChange = () => {
    let object = {
      ...filterState,
      startDate: moment(localStartDate).format("YYYY-MM-DD"),
      endDate: moment(localEndDate).format("YYYY-MM-DD"),
      startTime: moment(localStartTime).format("HH:mm"),
      endTime: moment(localEndTime).format("HH:mm"),
    };
    confirmDateTimeChange(!dateTimeChange);
    setDate(object);
    if (callback) {
      callback();
    }
  };

  return (
    <Col md={8}>
      <Row>
        <Col md={9}>
          <DateRangePicker
            dateAndTimePicker
            selectedStartDate={localStartDate}
            onChangeStartDate={(date) => setLocalStartDate(date)}
            selectedEndDate={localEndDate}
            onChangeEndDate={(date) => setLocalEndDate(date)}
            startLabel="Date Range with Time From"
            endLabel="Date Range with Time To"
            disabled={dateDisable}
            minDate={new Date("2020-01-01")}
            maxDate={maxDate ? new Date(maxDate) : new Date()}
            selectedStartTime={localStartTime}
            onChangeStartTime={(time) => setLocalStartTime(moment(time, "HH:mm").toDate())}
            selectedEndTime={localEndTime}
            onChangeEndTime={(time) => setLocalEndTime(moment(time, "HH:mm").toDate())}
            timeStartValue={moment(localStartTime).format("HH:mm")}
            timeEndValue={moment(localEndTime).format("HH:mm")}
            timedisabled={timeDisable}
            size="medium"
          />
        </Col>
        <Col>
          <IconButton>
            <DashSign>
              <span style={{ fontSize: "0.9rem" }} className="text-muted ml-2 mr-2">
                {moment.tz(moment.tz.guess()).format("zz")}
              </span>
            </DashSign>
            <ButtonDiv>
              <Button
                label={t("Apply Filter")}
                size="medium"
                onClick={applyChange}
                showIcon={false}
                buttonType="primary"
              />
            </ButtonDiv>
          </IconButton>
        </Col>
      </Row>
    </Col>
  );
};

export default ReactDateRange;
