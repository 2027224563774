import styled from "styled-components";

export const ChartDivs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
  @media screen and (max-width: 800px) {
    display: block;
    position: relative;
  }
`;

export const FilterDrop = styled.div`
  width: calc(100% - 50px);
  @media screen and (max-width: 800px) {
    width: 100%;

    .mobileWidth {
      width: 50%;
      max-width: 50% !important;
    }
  }
`;

export const StyledCol = styled.div`
  @media screen and (max-width: 1100px) {
    position: absolute;
    right: 0;
    bottom: 0px;
  }
`;

export const DateDropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  @media screen and (max-width: 1100px) {
    display: block;
  }
`;

export const InputWrapper = styled.div`
  width: 156px;
  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`;
