import styled, { css } from "styled-components";

// Conditional styles based on textDrop
export const buttonStyles = css`
  position: relative;
  background: none;
  border: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  padding: 0;
  font-size: ${({ theme }) => theme.fontSizes.font3} !important;
  text-align: center;
  transition:
    color 0.3s ease-in-out,
    background 0.3s ease-in-out;

  span {
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    font-size: ${({ theme }) => theme.fontSizes.font4};
    line-height: 0px;
    padding: 4px 8px;
    display: inline-block;
    vertical-align: middle;

    ${({ textDrop }) =>
      textDrop &&
      css`
        display: flex;
        align-items: center;
        margin-top: 7px;
      `}
  }

  &:active,
  &:focus {
    outline: 0px;
    border: 0px;
    span {
      border: 1px solid ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
      border-radius: 2px;
      @media (max-width: 1100px) {
        border: 0px solid ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    span {
      color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    }
  }

  &:hover {
    span {
      background: ${(props) => (props.disabled ? "" : "rgba(255,255,255, 0.1)")};
      border-radius: 4px;
    }
  }
`;

export const StyledButton = styled.button`
  ${buttonStyles}
`;

export const StyledLink = styled.a`
  ${buttonStyles}
  text-decoration: none !important;
  color: inherit;
  padding: 0px !important;
`;

export const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  position: relative;
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  text-align: center;
  opacity: ${(props) => (props.disabled ? "1" : "0")};
  @media (max-width: 1100px) {
    opacity: 1;
  }
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  transition: opacity 0.3s ease-in-out;
  font-weight: 400;

  ${StyledButton}:hover & , ${StyledLink}:hover & {
    opacity: 1;
  }
  ${StyledButton}:disabled & , ${StyledLink}:disabled & {
    opacity: 0;
  }

  ${StyledButton}:active &,
${StyledButton}:focus & {
    outline: 0px;
    border: 0px;
  }
`;
