import styled from "styled-components";

export const NoDataWrapper = styled.div`
  min-height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
`;

export const NoDataHeader = styled.h3`
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
`;

export const Details = styled.div``;

export const StepWrapper = styled.ol``;

export const Step = styled.li``;

export const ActionButton = styled.button`
  border-radius: 0.2rem;
  border: none;
  background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizes.font6};
  height: 40px;
  box-shadow: 1px 1px 2px rgb(58 130 213);
  padding: 0 30px;
  &:hover {
    background-color: rgba(13, 95, 190, 0.9);
  }
`;
