import React, { useEffect, useState } from "react";
import { Switch, Route, useRouteMatch, Redirect, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../../../components/BreadCrumb.component";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import LoginRecords from "./applicationActivityLogs/loginRecords/LoginRecords.component";
import LockUnlock from "./applicationActivityLogs/lockUnlockLogs/LockUnlock.component";
import {
  TabWrapper,
  DropdownWrapper,
  MobileViewDropdown,
  ButtonWrapper,
  ContainerDiv,
  ContentWrapper,
  LeftSection,
  RightSection,
} from "./ActivityLogs.component.styles";
import MenuBar from "./MenuBar.component";
import { getDropdownAccountList, setDropdownAccountId } from "../../../redux/dropdownFilters/action";
import { setSettingsDropdownAccountId } from "../../../redux/dropdownFilters/dropdownActions";
import DropdownComponent from "../../../components/DropdownComponent/Dropdown.component";

const AccountDropdown = ({ t, accountListWithAll, selectedAccount, setAccount, accountListLoading }) => {
  return (
    <div className="dropdown-wrapper">
      <DropdownComponent
        label={t("Account")}
        placeholder={"Select an Account"}
        options={accountListWithAll}
        value={selectedAccount}
        handleFilter={setAccount}
        size="medium"
        isLoading={accountListLoading}
      />
    </div>
  );
};

function ActivityLogs({
  updateActiveNavItem,
  isSiteAdmin,
  accountListLoading,
  dropdownAccountList,
  loginDetails,
  isSuperAdmin,
  isAccountAdmin,
  isAccountOwner,
  dropdownAccountId,
  setSettingsDropdownAccountId,
  getDropdownAccountList,
  permissions,
  setDropdownAccountId,
}) {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState({ value: "all", label: "All" });
  const [accountListWithAll, setAccountListWithAll] = useState([]);

  useEffect(() => {
    updateActiveNavItem("activityLogs");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAccountAdmin || isAccountOwner || isSiteAdmin) {
      setSettingsDropdownAccountId(loginDetails && loginDetails.accountId);
    }
  }, [loginDetails]);

  useEffect(() => {
    if (!dropdownAccountList || dropdownAccountList?.length === 0) {
      getDropdownAccountList();
    }
  }, []);

  useEffect(() => {
    if (isSuperAdmin && (dropdownAccountId === "all" || !dropdownAccountId)) {
      setSettingsDropdownAccountId(dropdownAccountList?.[0] && dropdownAccountList?.[0]?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownAccountList]);

  useEffect(() => {
    if (dropdownAccountList?.length > 0 && dropdownAccountList?.[0]?._id !== -1) {
      const optionListTemp = dropdownAccountList.map((option) => ({
        value: option._id,
        label: option.companyName,
      }));
      optionListTemp.unshift({ value: "all", label: "All Accounts" });
      setAccountListWithAll(optionListTemp);
    } else {
      setAccountListWithAll([{ value: "all", label: "All Accounts" }]);
    }
  }, [dropdownAccountList]);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const setAccount = (account) => {
    setSelectedAccount(account);
    setDropdownAccountId(account.value);
  };

  return (
    <div className="settings">
      <BreadCrumb />

      <ContainerDiv>
        <ContentWrapper isSidebarVisible={isSidebarVisible}>
          <LeftSection className="sidebar-col" isSidebarVisible={isSidebarVisible}>
            <MobileViewDropdown isSidebarVisible={isSidebarVisible}>
              {isSidebarVisible || (
                <Row>
                  <Col md={3}>
                    <TabWrapper className="tabAnimation">
                      {isSuperAdmin && (
                        <DropdownWrapper>
                          <AccountDropdown
                            t={t}
                            accountListWithAll={accountListWithAll}
                            selectedAccount={selectedAccount}
                            setAccount={setAccount}
                            accountListLoading={accountListLoading}
                          />
                        </DropdownWrapper>
                      )}
                    </TabWrapper>
                  </Col>
                </Row>
              )}
            </MobileViewDropdown>

            {isSidebarVisible && (
              <TabWrapper>
                {isSuperAdmin && (
                  <DropdownWrapper>
                    <AccountDropdown
                      t={t}
                      accountListWithAll={accountListWithAll}
                      selectedAccount={selectedAccount}
                      setAccount={setAccount}
                      accountListLoading={accountListLoading}
                    />
                  </DropdownWrapper>
                )}
                <MenuBar history={history} />
              </TabWrapper>
            )}
          </LeftSection>
          <RightSection isSidebarVisible={isSidebarVisible}>
            <ButtonWrapper className="absolute right-4" onClick={toggleSidebar}>
              {isSidebarVisible ? (
                <OverlayTrigger placement="top" overlay={<Tooltip id="expand-tooltip">Expand View</Tooltip>}>
                  <i className="icon icon-fullscreen-expand"></i>
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="collapse-tooltip">Collapse View</Tooltip>}
                >
                  <i className="icon icon-fullscreen-collapse"></i>
                </OverlayTrigger>
              )}
            </ButtonWrapper>

            <Switch>
              <Route
                path={`${match.url}/application-login-records`}
                render={(props) => <LoginRecords {...props} selectedAccount={selectedAccount} />}
              />
              <Route
                path={`${match.url}/lock-unlock-logs`}
                render={(props) => <LockUnlock {...props} selectedAccount={selectedAccount} />}
              />
              <Redirect to={`${match.url}/application-login-records`} />
            </Switch>
          </RightSection>
        </ContentWrapper>
      </ContainerDiv>

      <style jsx>{`
        @media (max-width: 768px) {
          .sidebar-col {
            width: 100% !important;
          }
          .content-col {
            width: 100% !important;
          }
        }
      `}</style>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isSiteAdmin: state.user.isSiteAdmin,
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountAdmin: state.user.isAccountAdmin,
  isAccountOwner: state.user.isAccountOwner,
  accountListLoading: state.dropdownFilters?.settings?.accountListLoading,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAccountId: state.dropdownFilters?.settings?.accountId,
  permissions: state.user?.loginDetails?.userData?.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setSettingsDropdownAccountId: (accountId) => dispatch(setSettingsDropdownAccountId(accountId)),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityLogs));
